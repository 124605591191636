var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "cols-4" },
    [
      _c(
        "v-col",
        [
          _c(
            "v-card",
            { staticClass: "mb-2" },
            [
              _c("v-card-title", [_vm._v(_vm._s(_vm.invoicingBatchesTitle))]),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  sortBy: _vm.headers.created,
                  "sort-desc": true,
                  headers: _vm.headers,
                  items: _vm.batchRows,
                  "items-per-page": -1,
                  "disable-filtering": "",
                  "disable-pagination": "",
                  "disable-sort": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.created",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.batchStatus",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: _vm._f(
                                                  "invoicingBatchStatusToColor"
                                                )(value)
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "invoicingBatchStatusToIcon"
                                              )(value)
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("invoicingBatchStatusToString")(value)
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.totalSum",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.reimbursementSum",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-2",
                                              attrs: {
                                                fab: "",
                                                disabled:
                                                  item.batchStatus !==
                                                    _vm.invoicingBatchStatuses
                                                      .Completed ||
                                                  item.batchStatus !==
                                                    _vm.invoicingBatchStatuses
                                                      .Unknown,
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelBatchInvoices(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-file-undo-outline ")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoicing.batches.cancelBatchInvoices"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-2",
                                              attrs: { fab: "", small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showLogMessages(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-message-text-outline ")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("invoicing.batches.showLogMessages")
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }