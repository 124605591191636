var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip-group",
    { attrs: { column: "", dark: "" } },
    [
      _vm._l(_vm.fares, function(fare, n) {
        return [
          _c(
            "v-tooltip",
            {
              key: n,
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              key: n,
                              attrs: {
                                close: "",
                                disabled: _vm.readOnly,
                                "close-icon": "mdi-delete",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openFareDialog(fare, n)
                                },
                                "click:close": function($event) {
                                  return _vm.deleteFare(n)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _vm.verbose
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fareTypeToText")(fare.fareType)
                                    ) +
                                      " - " +
                                      _vm._s(fare.tariffCategory) +
                                      ": " +
                                      _vm._s(fare.kilometerPrice) +
                                      " €/km, " +
                                      _vm._s(
                                        _vm._f("formatDistanceToKm")(
                                          fare.meters
                                        )
                                      ) +
                                      " km, " +
                                      _vm._s(fare.price) +
                                      " €"
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fareTypeToText")(fare.fareType)
                                    ) +
                                      " - " +
                                      _vm._s(fare.tariffCategory) +
                                      ": " +
                                      _vm._s(fare.price)
                                  )
                                ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm._f("fareToToolTipText")(fare)))])]
          )
        ]
      }),
      !_vm.readOnly && _vm.fares.length < _vm.maxAmount
        ? _c(
            "v-chip",
            {
              attrs: { color: "success" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openNewFareDialog($event)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("invoicing.addNewFare")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")])
            ],
            1
          )
        : _vm._e(),
      _c("FareDialog", {
        attrs: {
          isOpen: _vm.fareDialog,
          originalFare: _vm.selectedFare,
          isNewFare: _vm.newFare,
          allowedFareTypes: _vm.allowedFareTypes
        },
        on: {
          create: _vm.addFare,
          save: _vm.modifyFare,
          close: _vm.closeFareDialog
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }