var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportEvent
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(item, i) {
                return _c("v-tab", { key: i }, [
                  i !== 1 || (i === 1 && _vm.needsRehandling)
                    ? _c("div", [_vm._v(" " + _vm._s(item) + " ")])
                    : _vm._e()
                ])
              }),
              _c(
                "v-tab-item",
                { key: "0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Order Id",
                              disabled: _vm.readOnly || !_vm.isNew,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(21)
                              ],
                              counter: "21",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent, "orderId", $$v)
                              },
                              expression: "transportEvent.orderId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip start timestamp",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.dateTimeRule],
                              type: _vm.tripStartedTextFieldType,
                              placeholder: "yyyy-MM-dd HH:mm",
                              max: "2099-12-31T00:00",
                              required: ""
                            },
                            on: {
                              dblclick: _vm.dblClickTripStartedTypeChange,
                              focus: function($event) {
                                return $event.target.select()
                              }
                            },
                            model: {
                              value: _vm.transportEvent.trip.started,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "started",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.started"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip end timestamp",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.dateTimeRule],
                              type: _vm.tripEndedTextFieldType,
                              placeholder: "yyyy-MM-dd HH:mm",
                              max: "2099-12-31T00:00",
                              required: ""
                            },
                            on: {
                              dblclick: _vm.dblClickTripEndedTypeChange,
                              focus: function($event) {
                                return $event.target.select()
                              }
                            },
                            model: {
                              value: _vm.transportEvent.trip.ended,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent.trip, "ended", $$v)
                              },
                              expression: "transportEvent.trip.ended"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip time",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(3)
                              ],
                              counter: "3",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.duration,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "duration",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.duration"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Vehicle id",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(8)],
                              counter: "8",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.trip.carNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "carNumber",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.carNumber"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Meter id",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(14)],
                              counter: "14",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.transportEvent.fare.taximeterSerialNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "taximeterSerialNumber",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.fare.taximeterSerialNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip distance (m)",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(6)
                              ],
                              counter: "6",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.distance,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "distance",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.distance"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Waiting time",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(4)
                              ],
                              counter: "4",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.waitingTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingTime",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingTime"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Service number",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(12)],
                              counter: "14"
                            },
                            model: {
                              value: _vm.transportEvent.fare.paymentTerminalId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "paymentTerminalId",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.fare.paymentTerminalId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Driver id",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(12)],
                              counter: "12"
                            },
                            model: {
                              value: _vm.transportEvent.trip.driverId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "driverId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.driverId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Start address",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(100)],
                              counter: "100"
                            },
                            model: {
                              value: _vm.transportEvent.trip.startingPoint,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "startingPoint",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.startingPoint"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "End address",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(100)],
                              counter: "100"
                            },
                            model: {
                              value: _vm.transportEvent.trip.destination,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "destination",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.destination"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-subheader", { staticClass: "mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoicing.transportEventTypes.taxiPlus.taxiPlusCharge"
                        )
                      )
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Receipt number",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(8)
                              ],
                              counter: "8",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.receiptNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "receiptNumber",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.receiptNumber"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Card number",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(20)],
                              counter: "20",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.cardNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "cardNumber",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.cardNumber"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              items: [0.0, 10.0, 24.0, 25.5],
                              rules: [_vm.requiredRule],
                              label: "Vat percent",
                              disabled: _vm.readOnly,
                              required: ""
                            },
                            on: { input: _vm.calculateSums },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(" " + _vm._s(item) + " % ")]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(" " + _vm._s(item) + " % ")]
                                  }
                                }
                              ],
                              null,
                              false,
                              3334045436
                            ),
                            model: {
                              value: _vm.transportEvent.fare.vatPercent,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "vatPercent",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.vatPercent"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total amount",
                              value: _vm._f("formatPrice")(
                                _vm.transportEvent.fare.cost
                              ),
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9",
                              required: ""
                            },
                            on: { change: _vm.updateFareCostValue },
                            model: {
                              value: _vm.transportEvent.fare.cost,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent.fare, "cost", $$v)
                              },
                              expression: "transportEvent.fare.cost"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Discount amount",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9"
                            },
                            model: {
                              value: _vm.transportEvent.fare.discountAmount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "discountAmount",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.discountAmount"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total charge",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.reimbursement,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "reimbursement",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.reimbursement"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total charge vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9"
                            },
                            model: {
                              value: _vm.transportEvent.fare.reimbursementVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "reimbursementVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.reimbursementVat"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Total deductible",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7",
                              required: ""
                            },
                            on: { input: _vm.calculateSums },
                            model: {
                              value: _vm.transportEvent.fare.deductible,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "deductible",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.deductible"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Excess vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            model: {
                              value: _vm.transportEvent.fare.deductibleVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "deductibleVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.deductibleVat"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total waiting",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            on: { input: _vm.calculateSums },
                            model: {
                              value: _vm.transportEvent.fare.waitingCost,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingCost",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingCost"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Waiting vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            model: {
                              value: _vm.transportEvent.fare.waitingVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingVat"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "High tariff",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.transportEvent.fare.highTariff,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "highTariff",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.highTariff"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.extraCharges")) + " (0-5)")
                  ]),
                  _c("FeeChipGroup", {
                    ref: "feeChipGroup",
                    attrs: {
                      fees: _vm.transportEvent.fare.extras,
                      allowedFeeTypes: _vm.extraChargeTypes,
                      maxAmount: 5,
                      readOnly: _vm.readOnly,
                      useLikumaTexts: false
                    },
                    on: { update: _vm.updateFees }
                  }),
                  _c(
                    "v-row",
                    [
                      _c("v-textarea", {
                        staticClass: "mt-3 mx-2",
                        attrs: {
                          label: "Comments (optional)",
                          outlined: "",
                          rows: "3"
                        },
                        model: {
                          value: _vm.transportEvent.comments,
                          callback: function($$v) {
                            _vm.$set(_vm.transportEvent, "comments", $$v)
                          },
                          expression: "transportEvent.comments"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.readOnly
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.commentUpdating
                                  },
                                  on: { click: _vm.updateComment }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("invoicing.updateComments")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: {
                      label: "Set to be validated",
                      disabled: _vm.readOnly,
                      hint:
                        "Event cannot be modified during the validation process",
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.toBeValidated,
                      callback: function($$v) {
                        _vm.toBeValidated = $$v
                      },
                      expression: "toBeValidated"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: "1" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "4" } },
                        [
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "invoicing.transportEventTypes.receipt.cardRehandleTitle"
                                )
                              )
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.checkCardValidity(
                                    _vm.transportEvent.fare.cardNumber,
                                    _vm.transportEvent.fare.paymentTerminalId
                                  )
                                }
                              }
                            },
                            [_vm._v(" Tarkista kortti ")]
                          )
                        ],
                        1
                      ),
                      _vm.cardValidityResponse
                        ? _c(
                            "v-col",
                            { attrs: { cols: "8", md: "4" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-2",
                                attrs: {
                                  label: "Card number",
                                  readonly: "",
                                  "append-icon": _vm.cardValidityResponse
                                    .isValid
                                    ? "mdi-check"
                                    : "mdi-close"
                                },
                                model: {
                                  value: _vm.cardValidityResponse.cardNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.cardValidityResponse,
                                      "cardNumber",
                                      $$v
                                    )
                                  },
                                  expression: "cardValidityResponse.cardNumber"
                                }
                              }),
                              _c("v-switch", {
                                attrs: { label: "Tee kortinvarmennus" },
                                model: {
                                  value: _vm.authorize,
                                  callback: function($$v) {
                                    _vm.authorize = $$v
                                  },
                                  expression: "authorize"
                                }
                              }),
                              _vm.cardValidityResponse.taxiCardAttributes
                                ? _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mx-2",
                                      model: {
                                        value: _vm.selectedCardAttribute,
                                        callback: function($$v) {
                                          _vm.selectedCardAttribute = $$v
                                        },
                                        expression: "selectedCardAttribute"
                                      }
                                    },
                                    _vm._l(
                                      _vm.cardValidityResponse
                                        .taxiCardAttributes,
                                      function(attribute, index) {
                                        return _c("v-radio", {
                                          key: index,
                                          staticClass: "mx-2",
                                          attrs: {
                                            label:
                                              attribute.name +
                                              "(Tuotenumero: " +
                                              attribute.externalId +
                                              ")",
                                            value: attribute.externalId
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: { depressed: "", color: "warning" },
                                  on: {
                                    click: function($event) {
                                      return _vm.rehandleTaxicardPayment(
                                        _vm.transportEvent.id,
                                        _vm.selectedCardAttribute,
                                        _vm.authorize
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Uudelleenkäsittele korttitapahtuma ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }