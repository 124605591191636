<template>
    <v-chip-group column dark>
        <template v-for="(fare, n) in fares">
            <v-tooltip top :key="n">
                <template v-slot:activator="{ on }">
                    <v-chip
                        :key="n"
                        @click.stop="openFareDialog(fare, n)"
                        close
                        @click:close="deleteFare(n)"
                        :disabled="readOnly"
                        close-icon="mdi-delete"
                        color="primary"
                        v-on="on"
                    >
                    <span v-if="verbose">{{ fare.fareType | fareTypeToText }} - {{ fare.tariffCategory }}: {{ fare.kilometerPrice }} €/km, {{ fare.meters | formatDistanceToKm}} km,  {{fare.price}} €</span>
                    <span v-else>{{ fare.fareType | fareTypeToText }} - {{ fare.tariffCategory }}: {{fare.price}}</span>
                    </v-chip>
                </template>
                <span>{{ fare | fareToToolTipText }}</span>
            </v-tooltip>
        </template>
        <v-chip
            v-if="!readOnly && (fares.length < maxAmount)"
            color="success"
            @click.stop="openNewFareDialog"
        >
            {{$t("invoicing.addNewFare")}}
            <v-icon right>mdi-plus</v-icon>
        </v-chip>
        <FareDialog
            :isOpen="fareDialog"
            :originalFare="selectedFare"
            :isNewFare="newFare"
            :allowedFareTypes="allowedFareTypes"
            @create="addFare"
            @save="modifyFare"
            @close="closeFareDialog"
        ></FareDialog>
    </v-chip-group>
</template>

<script>
import FareDialog from "./FareDialog";
import { FareTypeMixin } from "../../../models/FareTypes";
import _ from "lodash";

export default {
    components: {
        FareDialog
    },
    mixins: [FareTypeMixin],
    props: {
        fares: {
            type: Array,
            default: () => []
        },
        allowedFareTypes: Array,
        maxAmount: Number,
        readOnly: Boolean,
        verbose: Boolean
    },
    data() {
        return {
            fareDialog: false,
            selectedFare: {},
            selectedFareIndex: -1,
            newFare: true
        };
    },
    methods: {
        openNewFareDialog() {
            this.selectedFare = {};
            this.newFare = true;
            this.fareDialog = true;
        },
        openFareDialog(fare, index) {
            this.selectedFare = fare;
            this.selectedFareIndex = index;
            this.newFare = false;
            this.fareDialog = true;
        },
        closeFareDialog() {
            this.selectedFare = {};
            this.fareDialog = false;
        },
        addFare(fare) {
            let newFares = _.cloneDeep(this.fares);
            newFares.push(fare);
            this.$emit("update", newFares);
        },
        deleteFare(index) {
            let newFares = _.cloneDeep(this.fares);
            newFares.splice(index, 1);
            this.$emit("update", newFares);
        },
        modifyFare(fare) {
            let newFares = _.cloneDeep(this.fares);
            this.$set(newFares, this.selectedFareIndex, fare);
            this.$emit("update", newFares);
        }
    }
};
</script>

<style>

</style>
