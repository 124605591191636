<template>
    <v-form
        ref="form"
        v-if="transportEvent"
        v-model="valid">
        <v-subheader class="mt-3">{{
          $t("invoicing.transportEventTypes.kela.kelaTripInfo")
        }}</v-subheader>
        <v-row>
            <v-col cols="12" sm="4">
                <v-row>
                  <v-col class="py-0" cols="8">
                  <v-text-field
                  v-model="transportEvent.user.ssn"
                  label="Henkilötunnus"
                  placeholder="Ei haettu"
                  ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-btn class="mt-3" color="primary"
                    @click="getSSN" :loading="ssnLoading">Hae</v-btn>
                  </v-col>
                </v-row>
                <v-text-field
                    v-model="transportEvent.trip.started"
                    label="Matka alkoi"
                    :disabled="readOnly"
                    :rules="[ requiredRule, dateTimeRule ]"
                    :type="tripStartedTextFieldType"
                    @dblclick="dblClickTripStartedTypeChange"
                    @focus="$event.target.select()"
                    placeholder="yyyy-MM-dd HH:mm"
                    max="2099-12-31T00:00"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.ended"
                    label="Matka loppui"
                    :disabled="readOnly"
                    :rules="[ requiredRule, dateTimeRule ]"
                    :type="tripEndedTextFieldType"
                    @dblclick="dblClickTripEndedTypeChange"
                    @focus="$event.target.select()"
                    placeholder="yyyy-MM-dd HH:mm"
                    max="2099-12-31T00:00"
                    required
                ></v-text-field>
                <v-select
                  v-model="transportEvent.trip.carType"
                  :items="carTypes"
                  :rules="[ enumValueRequiredRule ]"
                  label="Autotyyppi"
                  :disabled="readOnly"
                  required
                ></v-select>
                <v-select
                  v-model="transportEvent.trip.tripType"
                  :items="tripTypes"
                  :rules="[ enumValueRequiredRule ]"
                  label="Matkakohde"
                  :disabled="readOnly"
                  required
                ></v-select>
                <v-text-field
                    v-model="transportEvent.orderId"
                    label="Tilaustunnus"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(20) ]"
                    counter=20
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.routeNumber"
                    label="Reittitunnus"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(20) ]"
                    counter=20
                    required
                ></v-text-field>
                <v-select
                  v-model="transportEvent.kelaData.ordererType"
                  :items="ordererTypes"
                  :rules="[ enumValueRequiredRule ]"
                  label="Tilaaja"
                  :disabled="readOnly"
                  required
                ></v-select>
                <v-select
                    v-model="transportEvent.trip.carPool"
                    :items="carPoolValues"
                    label="Yhdistelymatka"
                    :disabled="readOnly"
                    class="mt-0"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <v-select
                  v-model="transportEvent.kelaData.carTypeRequirement"
                  :items="carTypeRequirements"
                  :rules="[ enumValueRequiredRule ]"
                  label="Autotyyppivaatimus"
                  :disabled="readOnly"
                  required
                ></v-select>
                <v-text-field
                    v-model="waypoints.startingPoint.address"
                    label="Lähtöpisteen osoite"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(100) ]"
                    counter=100
                ></v-text-field>
                <v-autocomplete
                    v-model="waypoints.startingPoint.municipalityCode"
                    :items="municipalities"
                    label="Lähtöpisteen kuntakoodi"
                    :disabled="readOnly"
                    required
                ></v-autocomplete>
                <v-row>
                  <v-col class="py-0" cols="8">
                <v-text-field
                    v-model="waypoints.startingPoint.coordinates"
                    label="Lähtöpisteen koordinaatit"
                    placeholder="Ei haettu"
                    disabled
                ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-btn class="mt-3" color="primary" @click="updateStartingPointCoordinates">Hae</v-btn>
                  </v-col>
                </v-row>
                <v-text-field
                    v-model="waypoints.destination.address"
                    label="Päätepisteen osoite"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(100) ]"
                    counter=100
                ></v-text-field>
                <v-autocomplete
                    v-model="waypoints.destination.municipalityCode"
                    :items="municipalities"
                    label="Päätepisteen kuntakoodi"
                    :disabled="readOnly"
                    required
                ></v-autocomplete>
                <v-row>
                  <v-col class="py-0" cols="8">
                <v-text-field
                    v-model="waypoints.destination.coordinates"
                    label="Päätepisteen koordinaatit"
                    placeholder="Ei haettu"
                    disabled
                ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-btn class="mt-3" color="primary" @click="updateDestinationCoordinates">Hae</v-btn>
                  </v-col>
                </v-row>
                <v-text-field
                    v-model="transportEvent.kelaData.escortCount"
                    label="SaattajaLkm"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(1) ]"
                    counter=1
                    required
                ></v-text-field>
                <v-slide-y-transition group hide-on-leave>
                  <v-text-field v-if="transportEvent.trip.carPool === 'K'" key=1
                    v-model="transportEvent.kelaData.passengerCount"
                    label="MatkustajaLkm - reitillä"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(2) ]"
                    counter=2
                    required
                  ></v-text-field>
                  <v-switch v-else key=2
                      v-model="transportEvent.kelaData.customerNotSuitableForJointRide"
                      label="Ei sovellu yhteiskuljetukseen"
                      :disabled="readOnly"
                      class="mt-0"
                  ></v-switch>
                </v-slide-y-transition>
            </v-col>
            <v-col cols="12" sm="4">
                <v-select
                    v-model="transportEvent.kelaData.tripChain"
                    label="Matkaketju"
                    :disabled="readOnly"
                    :items="tripChainValues"
                ></v-select>
                <v-select
                    v-model="transportEvent.kelaData.tripTopic"
                    label="Matkan aihe"
                    :disabled="readOnly"
                    :items="tripTopicValues"
                    :rules="[ enumValueRequiredRule ]"
                    required
                ></v-select>
                <v-text-field
                    v-model="transportEvent.fare.serviceProviderVatId"
                    label="Palveluntuottaja Y-tunnus"
                    :disabled="readOnly"
                    :rules="[ maxLengthWithoutDashRule(8) ]"
                    counter=9
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.serviceProviderName"
                    label="Palveluntuottaja nimi"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(35) ]"
                    counter=35
                ></v-text-field>
                <!--<v-text-field
                    v-model="transportEvent.fare.taximeterSerialNumber"
                    label="Palvelutunnus"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(14) ]"
                    counter=14
                ></v-text-field>-->
                <v-text-field
                    v-model="transportEvent.trip.carNumber"
                    label="Autonumero"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(8) ]"
                    counter=8
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.licenceNumber"
                    label="Rekisterinumero"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthWithoutDashRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.paymentTerminalId"
                    label="Palvelutunnus"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(12) ]"
                    counter=14
                ></v-text-field>
                <v-text-field
                    @change="updateFareDistance"
                    :value="this.transportEvent.fare.distance | formatDistanceToKm"
                    label="Kokonaismatka (km)"
                    :disabled="readOnly"
                    :rules="[ requiredRule, decimalRule, maxLengthRule(8) ]"
                    counter=8
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-subheader class="mt-3">{{$t("invoicing.transportEventTypes.kela.kelaCharge")}}</v-subheader>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="transportEvent.trip.driverId"
                    label="Kuljettajanumero"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(12) ]"
                    counter=12
                ></v-text-field>
                 <v-text-field
                    v-model="transportEvent.fare.receiptNumber"
                    label="Kuittinumero"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(20) ]"
                    counter=20
                    required
                ></v-text-field>
                <v-select
                    v-model="transportEvent.fare.vatPercent"
                    :items="[0.0, 10.0, 24.0, 25.5]"
                    :rules="[ requiredRule ]"
                    @input="calculateSums"
                    label="ALV"
                    :disabled="readOnly"
                    required
                >
                    <template slot="selection" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                    <template slot="item" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                </v-select>
                <v-text-field
                    v-model="transportEvent.kelaData.baseCost"
                    label="Perusmaksu"
                    :disabled="readOnly"
                    @input="calculateSums"
                    type="number"
                    :rules="[ maxLengthWithoutDecimalSeparatorRule(6), nonZeroValueRequiredRule ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                  v-model="transportEvent.kelaData.routePrice"
                    label="Reittihinta"
                    :disabled="readOnly"
                    @input="calculateSums"
                    type="number"
                    :rules="[ maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.cost"
                    label="Kustannus"
                    @input="calculateSums"
                    :disabled="readOnly || transportEvent.trip.carPool !== 'K'"
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=12 sm=6>
                <v-text-field
                    v-model="transportEvent.fare.reimbursement"
                    label="Korvaus"
                    disabled
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.reimbursementVat"
                    label="Korvaus ALV"
                    disabled
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.deductible"
                    label="Omavastuu"
                    :disabled="readOnly"
                    @input="calculateSums"
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.deductibleVat"
                    label="Omavastuu ALV"
                    disabled
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.kelaData.paymentCommitment"
                    label="Maksusitoumuksen tunnus"
                    :disabled="readOnly"
                    :rules="[maxLengthRule(10)]"
                    counter=10
                ></v-text-field>
                <v-row>
                  <v-col cols="auto">
                    <v-switch
                      v-model="transportEvent.kelaData.passengerEligibility"
                      label="Suorakorvausoikeus"
                      :disabled="readOnly"
                      class="mt-0">
                      </v-switch>
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      v-model="deductibleNotCollected"
                      label="Omavastuuta ei ole peritty"
                      :disabled="readOnly"
                      class="mt-0"
                    ></v-switch>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=auto>
            <v-subheader>{{$t("invoicing.fares")}}</v-subheader>
            <FareChipGroup
                ref="fareChipGroup"
                :fares="transportEvent.fare.tariffs"
                :allowedFareTypes="allowedFareTypes"
                :maxAmount=3
                :readOnly="readOnly"
                @update="updateFares"
                :verbose=true
            ></FareChipGroup>
          </v-col>
          <v-col cols=12 sm=auto>
            <v-subheader>{{$t("invoicing.extraCharges")}}</v-subheader>
            <FeeChipGroup
                ref="feeChipGroup"
                :fees="transportEvent.fare.extras"
                :allowedFeeTypes="extraChargeTypes"
                :maxAmount=5
                :readOnly="readOnly"
                :useKelaTexts=true
                @update="updateFees"
            ></FeeChipGroup>
          </v-col>
          <v-col cols=12 sm=auto>
            <v-subheader>{{$t("invoicing.waitFees")}}</v-subheader>
            <WaitFeeChipGroup
                ref="waitFeeChipGroup"
                :waitFees="transportEvent.fare.waitFees"
                :allowedWaitFeeTypes="['O', 'H']"
                :maxAmount=2
                :readOnly="readOnly"
                @update="updateWaitFees"
                :verbose=true
            ></WaitFeeChipGroup>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-textarea
              v-model="transportEvent.comments"
              label="Kommentit (ei mene Kelaan)"
              outlined
              rows=3
              class="mx-2"
          ></v-textarea>
        </v-row>
        <v-row>
          <v-textarea
              v-model="transportEvent.kelaData.extraInfo"
              label="Korvauskäsittelyyn liittyvä lisätieto Kelalle"
              outlined
              rows=3
              class="mx-2"
          ></v-textarea>
        </v-row>
        <v-row>
          <v-textarea
            v-model="transportEvent.purchaseInvoiceMessage"
            label="Lisätietoja tilitysraportille"
            :disabled="readOnly"
            outlined
            rows=3
            class="mx-2"
          ></v-textarea>
        </v-row>
        <v-row v-if="readOnly">
          <v-col>
            <v-btn
              color="primary"
              :loading="commentUpdating"
              @click="updateComment">
              {{$t("invoicing.updateComments")}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="transportEvent.validationFailReasonCode">
          <v-col>
            <v-text-field
              v-model="transportEvent.validationFailReason"
              label="Validointivirhe"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      <v-row>
        <v-col>
          <v-switch
              v-model="toNotBeValidated"
              label="Älä lähetä Kelaan"
              :disabled="readOnly"
              hint="Tapahtumaa ei lähetetä Kelalle tilitettäväksi kunnes tätä arvoa muutetaan"
              persistent-hint
              class="mt-0"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <SaveIncompleteConfirmationDialog
        :isOpen="saveIncompleteDialog"
        header="Tallenna keskeneräisenä"
        message="Tapahtumassa oli puutteita, haluatko tallentaa sen keskeneräisenä?"
        @save="saveIncomplete"
        @cancel="saveIncompleteDialog = false"
      ></SaveIncompleteConfirmationDialog>
    </v-form>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import FeeChipGroup from "./FeeChipGroups/FeeChipGroup";
import FareChipGroup from "./FeeChipGroups/FareChipGroup";
import WaitFeeChipGroup from "./FeeChipGroups/WaitFeeChipGroup";
import { ValidationStatusType } from "../../models/TransportEventStatusTypes";
import InputRuleMixin from "../Inputs/InputRuleMixin";
import _ from "lodash";
import { FeeTypeMixin, FeeType } from "../../models/FeeTypes";
import { FareTypeMixin, FareType } from "../../models/FareTypes";
import { municipalityOIDs } from "../../models/Municipalities";
import SaveIncompleteConfirmationDialog from "./Dialogs/SaveIncompleteConfirmationDialog.vue";

const GeoRepository = RepositoryFactory.get("geo");
const InvoicingRepository = RepositoryFactory.get("invoicing");

const mapToGeoCodeRequest = (addressString) => {
    const parts = addressString.split(",");
    if (parts.length < 2) {
        return null;
    }
    var address = parts.find(p => /\d/.test(p) && /[a-zA-Z]/.test(p));
    if (address == null || address.indexOf(" ") < 0) {
        return null;
    }
    var zipcode = parts.find(p => /\d/.test(p) && !/[a-zA-Z]/.test(p));
    var city = parts.slice().reverse().find(p => !/\d/.test(p) && /[a-zA-Z]/.test(p));
    var street = address.split(" ");
    var streetName = street.find(p => !/\d/.test(p) && /[a-zA-Z]/.test(p));
    var streetNumber = street.find(p => /\d/.test(p) && !/[a-zA-Z]/.test(p));
    return {
        Address: {
            StreetName: streetName,
            StreetNumber: streetNumber,
            City: city,
            Zipcode: zipcode,
            Country: "Finland"
        }
    };
};

async function getCoordinates(waypoint) {
    if (waypoint == null ||
    waypoint.address == null || waypoint.address.length < 3) {
        return "virheellinen osoite";
    }
    var request = mapToGeoCodeRequest(waypoint.address);
    if (request == null) {
        return "virheellinen osoite";
    }
    return GeoRepository.geoCodeAddress(request, "").then(response => {
        if (response == null || response.status !== 200) {
            return "osoitetta ei löytynyt";
        }
        return response.data.point.latitude + ", " + response.data.point.longitude;
    });
};

export default {
    components: { FeeChipGroup, FareChipGroup, WaitFeeChipGroup, SaveIncompleteConfirmationDialog },
    mixins: [ InputRuleMixin, FeeTypeMixin, FareTypeMixin ],
    props: {
        originalTransportEvent: Object,
        isNew: Boolean,
        readOnly: Boolean
    },
    data() {
        return {
            transportEvent: _.cloneDeep(this.originalTransportEvent),
            waypoints: { startingPoint: { address: "", municipalityCode: "", coordinates: "" }, destination: { address: "", municipalityCode: "", coordinates: "" } },
            valid: true,
            distanceKilometers: this.originalTransportEvent.fare.distance / 1000,
            decisionIds: [ "VPL", "SHL" ],
            tripTypes: [ { value: "J", text: "Julkinen" },
                { value: "Y", text: "Yksityinen" },
                { value: "K", text: "Kelan kuntoutus" },
                { value: "O", text: "Ostopalvelu" },
                { value: "S", text: "Opisk.th" }
            ],
            ordererTypes: [ { value: "T", text: "Terveydenhuolto" },
                { value: "U", text: "Tuttu taksi" },
                { value: "M", text: "Muu" },
                { value: "V", text: "Verkko terveydenhuolto" },
                { value: "X", text: "Verkko tuttu taksi" },
                { value: "Z", text: "Verkko muu" }
            ],
            carTypes: [{ value: 1, text: "Taksi" },
                { value: 4, text: "Invataksi" },
                { value: 6, text: "Paaritaksi" },
                { value: 7, text: "Henkilöliikenneluvallinen auto" },
                { value: 9, text: "Esteetön ajoneuvo (pieni)" }
            ],
            carTypeRequirements: [{ value: 1, text: "Taksi" },
                { value: 4, text: "Invataksi" },
                { value: 6, text: "Paaritaksi" }
            ],
            carPoolValues: [
                { value: null, text: "Ei" },
                { value: "K", text: "Kyllä" }
            ],
            tripChainValues: [
                { value: null, text: "-" },
                { value: "K", text: "K" },
                { value: "S", text: "S" }
            ],
            tripTopicValues: [
                { value: "S", text: "Sairaus" },
                { value: "L", text: "Liikenneolosuhteet" }
            ],
            deductibleCollectedValues: [
                { value: "", text: "Ei" },
                { value: "K", text: "Kyllä" }
            ],
            extraChargeTypes: [
                FeeType.ServiceFee,
                FeeType.AidFee,
                FeeType.OtherFee
            ],
            municipalities: municipalityOIDs,
            allowedFareTypes: [
                FareType.Single
            ],
            toNotBeValidated: false,
            tripEndedTextFieldType: "datetime-local",
            tripStartedTextFieldType: "datetime-local",
            ssnLoading: false,
            commentUpdating: false,
            saveIncompleteDialog: false,
            isTabViewMode: false
        };
    },
    computed: {
        deductibleNotCollected() {
            return !this.transportEvent.fare.deductibleCollected === "K";
        },
        carPool() {
            return this.transportEvent.trip.carPool;
        }
    },
    watch: {
        originalTransportEvent: {
            immediate: true,
            handler(val) {
                this.transportEvent = (!val) ? {} : _.cloneDeep(val);
                if (!this.transportEvent.fare || !this.transportEvent.fare.extras) {
                    this.transportEvent = {
                        fare: {
                            extras: []
                        }
                    };
                }

                let pick = null;
                let drop = null;
                if (this.transportEvent.trip.waypoints) {
                    pick = this.transportEvent.trip.waypoints.find(wp => wp.type === 1);
                    drop = this.transportEvent.trip.waypoints.find(wp => wp.type === 2);
                } else {
                    this.transportEvent.trip.waypoints = [];
                }

                this.waypoints = {
                    startingPoint: {
                        address: pick ? pick.location.address.streetName : this.transportEvent.trip.startingPoint,
                        municipalityCode: pick ? pick.location.address.additionalDetails.Kela_KuntaKoodi : "",
                        coordinates: pick ? (pick.location.point.latitude + ", " + pick.location.point.longitude) : ""
                    },
                    destination: {
                        address: drop ? drop.location.address.streetName : this.transportEvent.trip.destination,
                        municipalityCode: drop ? drop.location.address.additionalDetails.Kela_KuntaKoodi : "",
                        coordinates: drop ? (drop.location.point.latitude + ", " + drop.location.point.longitude) : ""
                    }
                };
                this.transportEvent.fare.vatPercent = this.transportEvent.fare.vatPercent;
                if (this.transportEvent.validationStatus !== ValidationStatusType.NotToBeValidated) {
                    this.transportEvent.validationStatus = ValidationStatusType.ReadyForValidation;
                }
                this.toNotBeValidated = this.transportEvent.validationStatus === ValidationStatusType.NotToBeValidated;
                if (!this.transportEvent.fare.distance) this.transportEvent.fare.distance = 0;
                this.distanceKilometers = this.transportEvent.fare.distance / 1000;
            }
        },
        toNotBeValidated: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.transportEvent.validationStatus = ValidationStatusType.NotToBeValidated;
                } else {
                    this.transportEvent.validationStatus = ValidationStatusType.ReadyForValidation;
                }
            }
        },
        carPool(val) {
            if (val !== "K") {
                this.transportEvent.fare.cost = this.transportEvent.kelaData.routePrice;
            }
        },
        deductibleNotCollected(val) {
            this.transportEvent.fare.deductibleCollected = val ? " " : "K";
        }
    },
    created() {
        let pick = null;
        let drop = null;
        if (this.transportEvent.trip.waypoints) {
            pick = this.transportEvent.trip.waypoints.find(wp => wp.type === 1);
            drop = this.transportEvent.trip.waypoints.find(wp => wp.type === 2);
        } else {
            this.transportEvent.trip.waypoints = [];
        }

        this.waypoints = {
            startingPoint: {
                address: pick ? pick.location.address.streetName : this.transportEvent.trip.startingPoint,
                municipalityCode: pick ? pick.location.address.additionalDetails.Kela_KuntaKoodi : "",
                coordinates: pick ? (pick.location.point.latitude + ", " + pick.location.point.longitude) : ""
            },
            destination: {
                address: drop ? drop.location.address.streetName : this.transportEvent.trip.destination,
                municipalityCode: drop ? drop.location.address.additionalDetails.Kela_KuntaKoodi : "",
                coordinates: drop ? (drop.location.point.latitude + ", " + drop.location.point.longitude) : ""
            }
        };
    },
    methods: {
        validate() {
            if (this.toNotBeValidated) return true;
            if (this.transportEvent.validationStatus === ValidationStatusType.New) return true;
            let isValid = this.$refs.form.validate();
            if (!isValid) {
                let errors = [];
                this.$refs.form.inputs.forEach(e => {
                    if (e.errorBucket && e.errorBucket.length) {
                        errors.push(e.label + ": " + e.errorBucket);
                    }
                });
                this.$emit("validationFailed", errors);
                this.saveIncompleteDialog = true;
            } else {
                this.updateWaypoints();
            }
            return this.$refs.form.validate();
        },
        saveIncomplete() {
            this.transportEvent.validationStatus = ValidationStatusType.New;
            this.$emit("saveIncomplete");
            this.saveIncompleteDialog = false;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        updateFees(fees) {
            this.transportEvent.fare.extras = fees;
            this.$refs.feeChipGroup.closeFeeDialog();
        },
        updateFares(fares) {
            this.transportEvent.fare.tariffs = fares;
            this.$refs.fareChipGroup.closeFareDialog();
        },
        updateWaitFees(waitFees) {
            this.transportEvent.fare.waitFees = waitFees;
            this.$refs.waitFeeChipGroup.closeWaitFeeDialog();
        },
        calculateSums() {
            let fare = this.transportEvent.fare;
            if (this.carPool !== "K") {
                fare.cost = this.transportEvent.kelaData.routePrice;
            }
            fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
            if (fare.reimbursement < 0) fare.reimbursement = 0;
            fare.reimbursementVat = this.calculateVat(fare.reimbursement, fare.vatPercent);
            fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
        },
        calculateVat(sumIncludingVat, vatPercent) {
            let vat = sumIncludingVat - (sumIncludingVat / ((vatPercent / 100.0) + 1));
            return vat.toFixed(2);
        },
        getSSN() {
            this.ssnLoading = true;
            InvoicingRepository.getSSN(this.transportEvent.id).then(response => {
                this.transportEvent.user.ssn = response.data.item;
            })
                .finally(() => {
                    this.ssnLoading = false;
                });
        },
        updateComment() {
            this.commentUpdating = true;
            this.$store.dispatch("transportEventTable/updateTransportEventComments", {
                id: this.transportEvent.id,
                comments: this.transportEvent.comments
            })
                .finally(() => {
                    this.commentUpdating = false;
                });
        },
        updateFareDistance(updateFareDistanceEvent) {
            if (updateFareDistanceEvent.indexOf(",") > -1 || updateFareDistanceEvent.indexOf(".") > -1) {
                updateFareDistanceEvent = Number(updateFareDistanceEvent.replace(",", "."));
            } else {
                updateFareDistanceEvent = Number(updateFareDistanceEvent);
            }

            this.transportEvent.fare.distance = Math.round(updateFareDistanceEvent * 1000);
        },
        async updateStartingPointCoordinates() {
            this.waypoints.startingPoint.coordinates = await getCoordinates(this.waypoints.startingPoint);
        },
        async updateDestinationCoordinates() {
            this.waypoints.destination.coordinates = await getCoordinates(this.waypoints.destination);
        },
        updateWaypoints() {
            let pickIndex = this.transportEvent.trip.waypoints.findIndex(wp => wp.type === 1);
            let startingPointLat = "";
            let startingPointLong = "";
            if (this.waypoints.startingPoint.coordinates.includes(",")) {
                startingPointLat = this.waypoints.startingPoint.coordinates.split(",")[0].trim();
                startingPointLong = this.waypoints.startingPoint.coordinates.split(",")[1].trim();
            }
            if (pickIndex >= 0) {
                this.transportEvent.trip.waypoints[pickIndex].location.address.streetName = this.waypoints.startingPoint.address;
                this.transportEvent.trip.waypoints[pickIndex].location.address.additionalDetails.Kela_KuntaKoodi = this.waypoints.startingPoint.municipalityCode;
                this.transportEvent.trip.waypoints[pickIndex].location.point.latitude = startingPointLat;
                this.transportEvent.trip.waypoints[pickIndex].location.point.longitude = startingPointLong;
            } else {
                this.transportEvent.trip.waypoints.push(
                    {
                        type: 1,
                        location: {
                            address: {
                                streetName: this.waypoints.startingPoint.address,
                                additionalDetails: {
                                    Kela_KuntaKoodi: this.waypoints.startingPoint.municipalityCode
                                }
                            },
                            point: {
                                latitude: startingPointLat,
                                longitude: startingPointLong
                            }
                        }
                    });
            }

            let dropIndex = this.transportEvent.trip.waypoints.findIndex(wp => wp.type === 2);
            let destinationLat = "";
            let destinationLong = "";
            if (this.waypoints.destination.coordinates.includes(",")) {
                destinationLat = this.waypoints.destination.coordinates.split(",")[0].trim();
                destinationLong = this.waypoints.destination.coordinates.split(",")[1].trim();
            }
            if (dropIndex >= 0) {
                this.transportEvent.trip.waypoints[dropIndex].location.address.streetName = this.waypoints.destination.address;
                this.transportEvent.trip.waypoints[dropIndex].location.address.additionalDetails.Kela_KuntaKoodi = this.waypoints.destination.municipalityCode;
                this.transportEvent.trip.waypoints[dropIndex].location.point.latitude = destinationLat;
                this.transportEvent.trip.waypoints[dropIndex].location.point.longitude = destinationLong;
            } else {
                this.transportEvent.trip.waypoints.push(
                    {
                        type: 2,
                        location: {
                            address: {
                                streetName: this.waypoints.destination.address,
                                additionalDetails: {
                                    Kela_KuntaKoodi: this.waypoints.destination.municipalityCode
                                }
                            },
                            point: {
                                latitude: destinationLat,
                                longitude: destinationLong
                            }
                        }
                    });
            }
        },
        dblClickTripEndedTypeChange() {
            if (this.tripEndedTextFieldType === "datetime-local") {
                this.tripEndedTextFieldType = "text";
            } else {
                this.tripEndedTextFieldType = "datetime-local";
            }
        },
        dblClickTripStartedTypeChange() {
            if (this.tripStartedTextFieldType === "datetime-local") {
                this.tripStartedTextFieldType = "text";
            } else {
                this.tripStartedTextFieldType = "datetime-local";
            }
        }
    }
};
</script>
