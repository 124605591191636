var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportEvent
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.startTime"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      type: _vm.tripStartedTextFieldType,
                      placeholder: "yyyy-MM-dd HH:mm",
                      max: "2099-12-31T00:00",
                      required: ""
                    },
                    on: {
                      dblclick: _vm.dblClickTripStartedTypeChange,
                      focus: function($event) {
                        return $event.target.select()
                      }
                    },
                    model: {
                      value: _vm.transportEvent.trip.started,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "started", $$v)
                      },
                      expression: "transportEvent.trip.started"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.endTime"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      type: _vm.tripEndedTextFieldType,
                      placeholder: "yyyy-MM-dd HH:mm",
                      max: "2099-12-31T00:00",
                      required: ""
                    },
                    on: {
                      dblclick: _vm.dblClickTripEndedTypeChange,
                      focus: function($event) {
                        return $event.target.select()
                      }
                    },
                    model: {
                      value: _vm.transportEvent.trip.ended,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "ended", $$v)
                      },
                      expression: "transportEvent.trip.ended"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Start address",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.transportEvent.trip.startingPoint,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "startingPoint", $$v)
                      },
                      expression: "transportEvent.trip.startingPoint"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "End address",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.transportEvent.trip.destination,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "destination", $$v)
                      },
                      expression: "transportEvent.trip.destination"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.carNumber"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(8)],
                      counter: "8",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.carNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "carNumber", $$v)
                      },
                      expression: "transportEvent.trip.carNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.serviceNumber"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(12)],
                      counter: "14"
                    },
                    model: {
                      value: _vm.transportEvent.fare.paymentTerminalId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "paymentTerminalId",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.paymentTerminalId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.driverId"),
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(10)],
                      counter: "14"
                    },
                    model: {
                      value: _vm.transportEvent.trip.driverId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "driverId", $$v)
                      },
                      expression: "transportEvent.trip.driverId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.orderId"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(40)],
                      counter: "40",
                      require: ""
                    },
                    model: {
                      value: _vm.transportEvent.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "orderId", $$v)
                      },
                      expression: "transportEvent.orderId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.routeNumber"),
                      rules: [_vm.maxLengthRule(20)],
                      counter: "20"
                    },
                    model: {
                      value: _vm.transportEvent.trip.routeNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "routeNumber", $$v)
                      },
                      expression: "transportEvent.trip.routeNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      value: _vm._f("formatDistanceToKm")(
                        _vm.transportEvent.fare.distance
                      ),
                      label: _vm.$t("invoicing.fields.distance"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(6)],
                      counter: "6",
                      required: "",
                      suffix: "km"
                    },
                    on: { change: _vm.updateFareDistanceValue }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", { staticClass: "mt-3" }, [
            _vm._v(
              _vm._s(
                _vm.$t("invoicing.transportEventTypes.receipt.receiptCharge")
              )
            )
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.receiptNumber"),
                      disabled: _vm.readOnly,
                      rules: [_vm.digitRule, _vm.maxLengthRule(20)],
                      counter: "20"
                    },
                    model: {
                      value: _vm.transportEvent.fare.receiptNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "receiptNumber", $$v)
                      },
                      expression: "transportEvent.fare.receiptNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.taximeterSerialNumber"),
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(14)],
                      counter: "14"
                    },
                    model: {
                      value: _vm.transportEvent.fare.taximeterSerialNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "taximeterSerialNumber",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.taximeterSerialNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.cardNumber"),
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(20)],
                      counter: "20"
                    },
                    model: {
                      value: _vm.transportEvent.fare.cardNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "cardNumber", $$v)
                      },
                      expression: "transportEvent.fare.cardNumber"
                    }
                  }),
                  _vm.transportEvent.kelaData.baseCost
                    ? _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("invoicing.fields.baseCost"),
                          disabled: _vm.readOnly,
                          type: "number",
                          rules: [_vm.maxLengthWithoutDecimalSeparatorRule(6)],
                          counter: "7"
                        },
                        on: { input: _vm.calculateSums },
                        model: {
                          value: _vm.transportEvent.kelaData.baseCost,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.transportEvent.kelaData,
                              "baseCost",
                              $$v
                            )
                          },
                          expression: "transportEvent.kelaData.baseCost"
                        }
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.paymentType"),
                      disabled: true
                    },
                    model: {
                      value: _vm.transportEvent.fare.paymentType,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "paymentType", $$v)
                      },
                      expression: "transportEvent.fare.paymentType"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: Object.values(_vm.paymentMethods),
                      label: _vm.$t("invoicing.fields.paymentMethod"),
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule]
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            var on = ref.on
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.paymentMethodToTranslationKey(item)
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "v-icon",
                                _vm._g(
                                  { staticStyle: { "margin-left": "5px" } },
                                  on
                                ),
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("paymentMethodToIcon")(item))
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            var attrs = ref.attrs
                            var on = ref.on
                            return [
                              _c(
                                "v-list-item",
                                _vm._g(
                                  _vm._b(
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var active = ref.active
                                              return [
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "paymentMethodToIcon"
                                                            )(item)
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              "no-gutters": "",
                                                              align: "center"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm.paymentMethodToTranslationKey(
                                                                      item
                                                                    )
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        "input-value": active
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    "v-list-item",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4251871835
                    ),
                    model: {
                      value: _vm.transportEvent.fare.paymentMethod,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "paymentMethod", $$v)
                      },
                      expression: "transportEvent.fare.paymentMethod"
                    }
                  }),
                  _vm.transportEvent.fare.paymentMethod ===
                    _vm.paymentMethods.Invoice ||
                  _vm.transportEvent.fare.paymentMethod ===
                    _vm.paymentMethods.App
                    ? _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("invoicing.fields.costCenter"),
                          disabled: _vm.readOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.transportEvent.customer.costCentreId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.transportEvent.customer,
                              "costCentreId",
                              $$v
                            )
                          },
                          expression: "transportEvent.customer.costCentreId"
                        }
                      })
                    : _vm._e(),
                  _c("v-select", {
                    attrs: {
                      items: [0.0, 10.0, 24.0, 25.5],
                      rules: [_vm.requiredRule],
                      label: _vm.$t("invoicing.fields.vatPercent"),
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        }
                      ],
                      null,
                      false,
                      3334045436
                    ),
                    model: {
                      value: _vm.transportEvent.fare.vatPercent,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "vatPercent", $$v)
                      },
                      expression: "transportEvent.fare.vatPercent"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.cost"),
                      value: _vm._f("formatPrice")(
                        _vm.transportEvent.fare.cost
                      ),
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      suffix: "€",
                      required: ""
                    },
                    on: { change: _vm.updateFareCostValue }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      value: _vm._f("formatPrice")(
                        _vm.transportEvent.fare.deductible
                      ),
                      label: _vm.$t("invoicing.fields.deductible"),
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7",
                      suffix: "€",
                      required: ""
                    },
                    on: {
                      change: _vm.updateFareDeductibleValue,
                      input: _vm.calculateSums
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoicing.fields.reimbursement"),
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      suffix: "€",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.reimbursement,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "reimbursement", $$v)
                      },
                      expression: "transportEvent.fare.reimbursement"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      value: _vm._f("formatPrice")(
                        _vm.transportEvent.fare.discountAmount
                      ),
                      label: _vm.$t("invoicing.fields.discount"),
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      suffix: "€"
                    },
                    on: { change: _vm.updateFareDiscountValue }
                  }),
                  _vm.transportEvent.kelaData.routePrice
                    ? _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("invoicing.fields.routeTotal"),
                          type: "number",
                          rules: [_vm.maxLengthWithoutDecimalSeparatorRule(6)],
                          counter: "7",
                          suffix: "€"
                        },
                        on: { input: _vm.calculateSums },
                        model: {
                          value: _vm.transportEvent.kelaData.routePrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.transportEvent.kelaData,
                              "routePrice",
                              $$v
                            )
                          },
                          expression: "transportEvent.kelaData.routePrice"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.fares")))
                  ]),
                  _c("FareChipGroup", {
                    ref: "fareChipGroup",
                    attrs: {
                      fares: _vm.transportEvent.fare.tariffs,
                      allowedFareTypes: _vm.allowedFareTypes,
                      maxAmount: 3,
                      readOnly: _vm.readOnly,
                      verbose: true
                    },
                    on: { update: _vm.updateFares }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.extraCharges")))
                  ]),
                  _c("FeeChipGroup", {
                    ref: "feeChipGroup",
                    attrs: {
                      fees: _vm.transportEvent.fare.extras,
                      allowedFeeTypes: _vm.allowedFeeTypes,
                      maxAmount: 5,
                      readOnly: _vm.readOnly
                    },
                    on: { update: _vm.updateFees }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.waitFees")))
                  ]),
                  _c("WaitFeeChipGroup", {
                    ref: "waitFeeChipGroup",
                    attrs: {
                      waitFees: _vm.transportEvent.fare.waitFees,
                      allowedWaitFeeTypes: _vm.allowedWaitFeeTypes,
                      maxAmount: 2,
                      readOnly: _vm.readOnly
                    },
                    on: { update: _vm.updateWaitFees }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6" },
            [
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  label: _vm.$t("invoicing.fields.comments"),
                  outlined: "",
                  rows: "3"
                },
                model: {
                  value: _vm.transportEvent.comments,
                  callback: function($$v) {
                    _vm.$set(_vm.transportEvent, "comments", $$v)
                  },
                  expression: "transportEvent.comments"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.transportEvent.fare.paymentMethod ===
                _vm.paymentMethods.Invoice &&
              _vm.transportEvent.customer.costCentreId
                ? _c("v-textarea", {
                    staticClass: "mx-2",
                    attrs: {
                      label: _vm.$t("invoicing.fields.salesInvoiceMessage"),
                      disabled: _vm.readOnly,
                      outlined: "",
                      rows: "3"
                    },
                    model: {
                      value: _vm.transportEvent.salesInvoiceMessage,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "salesInvoiceMessage", $$v)
                      },
                      expression: "transportEvent.salesInvoiceMessage"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  label: _vm.$t("invoicing.fields.purchaseInvoiceMessage"),
                  disabled: _vm.readOnly,
                  outlined: "",
                  rows: "3"
                },
                model: {
                  value: _vm.transportEvent.purchaseInvoiceMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.transportEvent, "purchaseInvoiceMessage", $$v)
                  },
                  expression: "transportEvent.purchaseInvoiceMessage"
                }
              })
            ],
            1
          ),
          _vm.readOnly
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.commentUpdating
                          },
                          on: { click: _vm.updateComment }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("invoicing.updateComments")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }