var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "cols-4" },
    [
      _c(
        "v-col",
        [
          _c(
            "v-card",
            { staticClass: "mb-2" },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(_vm._s(_vm.readyForInvoicingTitle) + " "),
                  _c("v-select", {
                    staticClass: "ml-3",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      items: _vm.invoiceOptions,
                      "item-text": "optionsName",
                      "item-value": "id",
                      label: "Invoice option"
                    },
                    model: {
                      value: _vm.selectedInvoiceOptionId,
                      callback: function($$v) {
                        _vm.selectedInvoiceOptionId = $$v
                      },
                      expression: "selectedInvoiceOptionId"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      on: {
                        click: function($event) {
                          _vm.showInvoiceOptionsDialog = !_vm.showInvoiceOptionsDialog
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.edit")) + " ")]
                  )
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.allFilters,
                  "items-per-page": -1,
                  "disable-filtering": "",
                  "disable-pagination": "",
                  "disable-sort": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.totalReimbursementSum",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.oldestTripStart",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.newestTripStart",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-2",
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.startInvoicing(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-motion-play-outline ")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("invoicing.createInvoices")) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-2",
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchEvents(item)
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-magnify ")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("invoicing.searchEvents")) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mx-2",
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openInvoiceRowSettingsDialog(
                                                    item,
                                                    "edit"
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v(" mdi-cog ")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " ")
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      block: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openInvoiceRowSettingsDialog(
                                                          null,
                                                          "create"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-plus")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("common.addNew")
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("common.addNew")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("InvoiceOptionsSettingsDialog", {
        attrs: {
          show: _vm.showInvoiceOptionsDialog,
          selectedInvoiceOption: _vm.selectedInvoiceOption || {},
          invoicingOptionsString:
            _vm.selectedInvoiceOption.invoicingOptionsString
        },
        on: {
          save: function($event) {
            return _vm.handleSave("invoiceOptionsSettingsDialog")
          },
          cancel: function($event) {
            return _vm.handleCancel("invoiceOptionsSettingsDialog")
          }
        }
      }),
      _c("InvoiceRowSettingsDialog", {
        attrs: {
          show: _vm.showInvoiceRowSettingsDialog,
          selectedRowItem: _vm.selectedInvoiceRowItem,
          invoiceOptions: _vm.invoiceOptions,
          allTransportEventFilters: _vm.allTransportEventFilters,
          mode: _vm.rowDialogMode
        },
        on: {
          save: _vm.handleSave,
          create: _vm.handleCreate,
          cancel: function($event) {
            return _vm.handleCancel("invoiceRowSettingsDialog")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }