<template>
    <v-form
        ref="form"
        v-if="transportEvent"
        v-model="valid">
        <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.trip.started"
          :label="$t('invoicing.fields.startTime')"
          :disabled="readOnly"
          :rules="[ requiredRule, dateTimeRule ]"
          :type="tripStartedTextFieldType"
          @dblclick="dblClickTripStartedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.ended"
          :label="$t('invoicing.fields.endTime')"
          :disabled="readOnly"
          :rules="[ requiredRule, dateTimeRule ]"
          :type="tripEndedTextFieldType"
          @dblclick="dblClickTripEndedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.startingPoint"
          label="Start address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.destination"
          label="End address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.trip.carNumber"
          :label="$t('invoicing.fields.carNumber')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(8) ]"
          counter=8
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentTerminalId"
          :label="$t('invoicing.fields.serviceNumber')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(12) ]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.driverId"
          :label="$t('invoicing.fields.driverId')"
          :disabled="readOnly"
          :rules="[ maxLengthRule(10) ]"
          counter="14"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.orderId"
          :label="$t('invoicing.fields.orderId')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(40) ]"
          counter=40
          require
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.routeNumber"
          :label="$t('invoicing.fields.routeNumber')"
          :rules="[ maxLengthRule(20) ]"
          counter=20
        ></v-text-field>
        <v-text-field
          @change="updateFareDistanceValue"
          :value="transportEvent.fare.distance | formatDistanceToKm"
          :label="$t('invoicing.fields.distance')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(6) ]"
          counter=6
          required
          suffix="km"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader class="mt-3">{{
          $t("invoicing.transportEventTypes.receipt.receiptCharge")
    }}</v-subheader>
    <v-row>
      <v-col cols="8" md="4">
        <v-text-field
          v-model="transportEvent.fare.receiptNumber"
          :label="$t('invoicing.fields.receiptNumber')"
          :disabled="readOnly"
          :rules="[ digitRule, maxLengthRule(20)]"
          counter="20"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.taximeterSerialNumber"
          :label="$t('invoicing.fields.taximeterSerialNumber')"
          :disabled="readOnly"
          :rules="[ maxLengthRule(14) ]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.cardNumber"
          :label="$t('invoicing.fields.cardNumber')"
          :disabled="readOnly"
          :rules="[maxLengthRule(20)]"
          counter="20">
        </v-text-field>
        <v-text-field
          v-if="transportEvent.kelaData.baseCost"
          v-model="transportEvent.kelaData.baseCost"
          :label="$t('invoicing.fields.baseCost')"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[ maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentType"
          :label="$t('invoicing.fields.paymentType')"
          :disabled="true"
        ></v-text-field>
      </v-col>
        <v-col cols="8" md="4">
        <v-select
          v-model="transportEvent.fare.paymentMethod"
          :items="Object.values(paymentMethods)"
          :label="$t('invoicing.fields.paymentMethod')"
          :disabled="readOnly"
          :rules="[ requiredRule ]">
          <template v-slot:selection="{item, on}">
            {{ $t(paymentMethodToTranslationKey(item)) }}
            <v-icon v-on="on" style="margin-left: 5px">{{ item | paymentMethodToIcon }}</v-icon>
          </template>
          <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-icon v-on="on" style="margin-right: 5px">{{ item | paymentMethodToIcon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  {{ $t(paymentMethodToTranslationKey(item)) }}
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox
                  :input-value="active"
              ></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </template>
        </v-select>
        <v-text-field
          v-if="transportEvent.fare.paymentMethod === paymentMethods.Invoice ||
                transportEvent.fare.paymentMethod === paymentMethods.App"
          v-model="transportEvent.customer.costCentreId"
          :label="$t('invoicing.fields.costCenter')"
          :disabled="readOnly"
          required
        ></v-text-field>
        <v-select
          v-model="transportEvent.fare.vatPercent"
          :items="[0.0, 10.0, 24.0, 25.5]"
          :rules="[ requiredRule ]"
          @input="calculateSums"
          :label="$t('invoicing.fields.vatPercent')"
          :disabled="readOnly"
          required>
          <template slot="selection" slot-scope="{ item }">
              {{ item }} %
          </template>
          <template slot="item" slot-scope="{ item }">
              {{ item }} %
          </template>
        </v-select>
        <v-text-field
        :label="$t('invoicing.fields.cost')"
          @change="updateFareCostValue"
          :value="transportEvent.fare.cost | formatPrice"
          :disabled="readOnly"
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="8" md="4">
        <v-text-field
          @change="updateFareDeductibleValue"
          :value="transportEvent.fare.deductible | formatPrice"
          :label="$t('invoicing.fields.deductible')"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
          suffix="€"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursement"
          :label="$t('invoicing.fields.reimbursement')"
          disabled
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
          required
        ></v-text-field>
        <v-text-field
          @change="updateFareDiscountValue"
          :value="transportEvent.fare.discountAmount | formatPrice"
          :label="$t('invoicing.fields.discount')"
          :disabled="readOnly"
          type="number"
          :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
        ></v-text-field>
        <v-text-field
          v-if="transportEvent.kelaData.routePrice"
          v-model="transportEvent.kelaData.routePrice"
          :label="$t('invoicing.fields.routeTotal')"
          @input="calculateSums"
          type="number"
          :rules="[ maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
          suffix="€"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.fares") }}</v-subheader>
        <FareChipGroup
          ref="fareChipGroup"
          :fares="transportEvent.fare.tariffs"
          :allowedFareTypes="allowedFareTypes"
          :maxAmount="3"
          :readOnly="readOnly"
          @update="updateFares"
          :verbose=true
        ></FareChipGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.extraCharges") }}</v-subheader>
        <FeeChipGroup
          ref="feeChipGroup"
          :fees="transportEvent.fare.extras"
          :allowedFeeTypes="allowedFeeTypes"
          :maxAmount="5"
          :readOnly="readOnly"
          @update="updateFees"
        ></FeeChipGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.waitFees") }}</v-subheader>
        <WaitFeeChipGroup
          ref="waitFeeChipGroup"
          :waitFees="transportEvent.fare.waitFees"
          :allowedWaitFeeTypes="allowedWaitFeeTypes"
          :maxAmount="2"
          :readOnly="readOnly"
          @update="updateWaitFees"
        ></WaitFeeChipGroup>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-textarea
          v-model="transportEvent.comments"
          :label="$t('invoicing.fields.comments')"
          outlined
          rows=3
          class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        v-if="transportEvent.fare.paymentMethod === paymentMethods.Invoice && transportEvent.customer.costCentreId"
        v-model="transportEvent.salesInvoiceMessage"
        :label="$t('invoicing.fields.salesInvoiceMessage')"
        :disabled="readOnly"
        outlined
        rows=3
        class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        v-model="transportEvent.purchaseInvoiceMessage"
        :label="$t('invoicing.fields.purchaseInvoiceMessage')"
        :disabled="readOnly"
        outlined
        rows=3
        class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row v-if="readOnly">
      <v-col>
        <v-btn
          color="primary"
          :loading="commentUpdating"
          @click="updateComment">
          {{$t("invoicing.updateComments")}}
        </v-btn>
      </v-col>
    </v-row>
    </v-form>
</template>

<script>
import InputRuleMixin from "../Inputs/InputRuleMixin";
import FeeChipGroup from "./FeeChipGroups/FeeChipGroup";
import FareChipGroup from "./FeeChipGroups/FareChipGroup";
import WaitFeeChipGroup from "./FeeChipGroups/WaitFeeChipGroup";
import { ValidationStatusType, TransportEventStatusMixin } from "../../models/TransportEventStatusTypes";
import { TransportEventTypeMixin } from "../../models/TransportEventTypes";
import { TransportEventInvoicingMixin } from "../../models/TransportEventInvoicingStates";
import { FeeTypeMixin, FeeTypeMitax } from "../../models/FeeTypes";
import { FareTypeMixin, FareTypeMitax } from "../../models/FareTypes";
import { PaymentMethodMixin } from "../../models/PaymentMethods";
import _ from "lodash";

export default {
    components: { FeeChipGroup, FareChipGroup, WaitFeeChipGroup },
    mixins: [ InputRuleMixin, FeeTypeMixin, FareTypeMixin, PaymentMethodMixin,
        TransportEventStatusMixin, TransportEventTypeMixin, TransportEventInvoicingMixin ],
    props: {
        originalTransportEvent: Object,
        isNew: Boolean,
        readOnly: Boolean
    },
    data() {
        return {
            transportEvent: _.cloneDeep(this.originalTransportEvent),
            valid: true,
            allowedFareTypes: [
                FareTypeMitax.Single,
                FareTypeMitax.Double,
                FareTypeMitax.Fixed,
                FareTypeMitax.Wait
            ],
            allowedFeeTypes: [
                FeeTypeMitax.T,
                FeeTypeMitax.E,
                FeeTypeMitax.A,
                FeeTypeMitax.P,
                FeeTypeMitax.K,
                FeeTypeMitax.L,
                FeeTypeMitax.M
            ],
            allowedWaitFeeTypes: ["O"],
            toNotBeValidated: false,
            commentUpdating: false,
            tripEndedTextFieldType: "datetime-local",
            tripStartedTextFieldType: "datetime-local"
        };
    },
    computed: {
        paymentMethodText() {
            return this.$t(this.paymentMethodToTranslationKey(this.transportEvent.fare.paymentMethod));
        }
    },
    watch: {
        originalTransportEvent(val) {
            this.transportEvent = (!val) ? {} : _.cloneDeep(val);
        },
        toNotBeValidated(val) {
            if (val) {
                this.transportEvent.validationStatus =
          this.originalTransportEvent.validationStatus;
            } else {
                this.transportEvent.validationStatus =
          ValidationStatusType.ReadyForValidation;
            }
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        calculateSums() {
            let fare = this.transportEvent.fare;
            fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
            fare.reimbursementVat = this.calculateVat(fare.reimbursement, fare.vatPercent);
            fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
        },
        updateFees(fees) {
            this.transportEvent.fare.extras = fees;
            this.$refs.feeChipGroup.closeFeeDialog();
        },
        updateFares(fares) {
            this.transportEvent.fare.tariffs = fares;
            this.$refs.fareChipGroup.closeFareDialog();
        },
        updateWaitFees(waitFees) {
            this.transportEvent.fare.waitFees = waitFees;
            this.$refs.waitFeeChipGroup.closeWaitFeeDialog();
        },
        calculateVat(sumIncludingVat, vatPercent) {
            let vat = sumIncludingVat - sumIncludingVat / (vatPercent / 100.0 + 1);
            return vat.toFixed(2);
        },
        updateFareCostValue(updateFareCostEvent) {
            this.transportEvent.fare.cost = updateFareCostEvent;
            this.calculateSums();
        },
        updateFareDistanceValue(updateFareDistanceEvent) {
            if (updateFareDistanceEvent.indexOf(",") > -1 || updateFareDistanceEvent.indexOf(".") > -1) {
                updateFareDistanceEvent = Number(updateFareDistanceEvent.replace(",", "."));
            } else {
                updateFareDistanceEvent = Number(updateFareDistanceEvent);
            }
            this.transportEvent.fare.distance = Math.round(updateFareDistanceEvent * 1000);
        },
        updateFareDeductibleValue(updateFareDeductibleEvent) {
            this.transportEvent.fare.deductible = updateFareDeductibleEvent;
            this.calculateSums();
        },
        updateFareDiscountValue(updateFareDiscountEvent) {
            this.transportEvent.fare.discountAmount = updateFareDiscountEvent;
        },
        updateComment() {
            this.commentUpdating = true;
            this.$store.dispatch("transportEventTable/updateTransportEventComments", {
                id: this.transportEvent.id,
                comments: this.transportEvent.comments
            })
                .finally(() => {
                    this.commentUpdating = false;
                });
        },
        dblClickTripEndedTypeChange(e) {
            if (this.tripEndedTextFieldType === "datetime-local") {
                this.tripEndedTextFieldType = "text";
            } else {
                this.tripEndedTextFieldType = "datetime-local";
            }
        },
        dblClickTripStartedTypeChange(e) {
            if (this.tripStartedTextFieldType === "datetime-local") {
                this.tripStartedTextFieldType = "text";
            } else {
                this.tripStartedTextFieldType = "datetime-local";
            }
        }
    }
};
</script>

<style>

</style>
