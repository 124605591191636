<template>
    <v-dialog v-model="showDialog" max-width="800" @input="onDialogInput">
        <v-card>
            <v-card-title>
                <span id="headline">{{ selectedInvoiceOption ? selectedInvoiceOption.optionsName : "" }}</span>
            </v-card-title>
            <v-card-text style="color: black;">
                <div v-if="selectedInvoiceOption">
                    <v-form ref="form" @submit.prevent="submitForm">
                        <v-row v-for="(option, key) in selectedInvoiceOption.invcoicingOptions" :key="key" class="mx-4">
                            <v-col cols="8">
                                <template v-if="getOptionTypeLabel(option.type) === 'String'">
                                    <v-text-field v-model="option.value" :label="option.name" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'Number'">
                                    <v-text-field v-model="option.value" :label="option.name" type="number" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'Decimal'">
                                    <v-text-field v-model="option.value" :label="option.name" type="number" step="0.01" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'Date'">
                                    <v-date-picker v-model="option.value" :label="option.name" :disabled="!option.canBeModified"></v-date-picker>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'Boolean'">
                                    <v-switch v-model="booleanValues[key]" :label="option.name" :disabled="!option.canBeModified"></v-switch>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'Guid'">
                                    <v-text-field v-model="option.value" :label="option.name" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'DictionaryString'">
                                    <v-text-field v-model="option.value" :label="option.name" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'DictionaryNumber'">
                                    <v-text-field v-model="option.value" :label="option.name" type="number" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                                <template v-if="getOptionTypeLabel(option.type) === 'DictionaryDecimal'">
                                    <v-text-field v-model="option.value" :label="option.name" type="number" step="0.01" :disabled="!option.canBeModified"></v-text-field>
                                </template>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn @click="submitForm" color="primary">
                    {{ $t("common.save") }}
                </v-btn>
                <v-btn @click="$emit('cancel')" class="ml-4">
                    {{ $t("common.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { InvoiceOptionsTypeMixin } from "../../../models/InvoiceOptionsType";

export default {
    mixins: [InvoiceOptionsTypeMixin],
    props: {
        show: { type: Boolean, required: true },
        selectedInvoiceOption: { type: Object }
    },
    data() {
        return {
            showDialog: this.show,
            booleanValues: {}
        };
    },
    watch: {
        show(newVal) { this.showDialog = newVal; },
        selectedInvoiceOption: {
            handler(newVal) {
                if (newVal) {
                    this.initializeBooleanValues();
                }
            },
            deep: true
        }
    },
    methods: {
        onDialogInput(value) {
            if (value === false) {
                this.$emit("cancel");
            }
        },
        initializeBooleanValues() {
            this.booleanValues = {};
            this.selectedInvoiceOption.invcoicingOptions.forEach((option, key) => {
                if (this.getOptionTypeLabel(option.type) === "Boolean") {
                    this.booleanValues[key] = this.toBoolean(option.value);
                }
            });
        },
        submitForm() {
            this.selectedInvoiceOption.invcoicingOptions.forEach((option, key) => {
                if (this.getOptionTypeLabel(option.type) === "Boolean") {
                    option.value = this.fromBoolean(this.booleanValues[key]);
                }
            });
            if (this.$refs.form.validate()) {
                this.$emit("save", this.selectedInvoiceOption);
            }
        }
    }
};
</script>
