export const InvoicingBatchStatus = {
    Unknown: 0,
    Started: 1,
    InProgress: 2,
    Completed: 3,
    Cancelled: 4
};

export const InvoicingBatchStatusMixin = {
    data() {
        return {
            invoicingBatchStatuses: InvoicingBatchStatus
        };
    },
    filters: {
        invoicingBatchStatusToString(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "-";
            case InvoicingBatchStatus.Started:
                return "Started";
            case InvoicingBatchStatus.InProgress:
                return "In Progress";
            case InvoicingBatchStatus.Completed:
                return "Completed";
            case InvoicingBatchStatus.Cancelled:
                return "Cancelled";
            }
        },
        invoicingBatchStatusToColor(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "grey darken-4";
            case InvoicingBatchStatus.Started:
                return "orange darken-4";
            case InvoicingBatchStatus.InProgress:
                return "blue darken-4";
            case InvoicingBatchStatus.Completed:
                return "green darken-4";
            case InvoicingBatchStatus.Cancelled:
                return "red darken-4";
            }
        },
        invoicingBatchStatusToIcon(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "mdi-help-circle";
            case InvoicingBatchStatus.Started:
                return "mdi-file-clock-outline";
            case InvoicingBatchStatus.InProgress:
                return "mdi-file-sync-outline";
            case InvoicingBatchStatus.Completed:
                return "mdi-file-document-check-outline";
            case InvoicingBatchStatus.Cancelled:
                return "mdi-file-undo-outline";
            }
        }
    }
};
