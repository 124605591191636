<template>
    <v-dialog v-model="isOpen" max-width="1400" @click:outside="$emit('close')">
      <v-row class="mx-0">
        <v-col
        class="pa-0">
        <v-card>
            <v-toolbar short dark color="primary">
                <v-card-title>
                    <span v-if="correctionMode">{{$t("invoicing.correctTransportEvent")}}</span>
                    <span v-else-if="!value">{{$t("invoicing.createTransportEvent")}}</span>
                    <span v-else>{{$t("invoicing.editTransportEvent")}}</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <!--<v-btn
                text
                @click="browseReceipts = !browseReceipts">
                 {{browseReceipts ? "Piilota" : "Selaa selvityksiä >"}}
                </v-btn>-->
            </v-toolbar>
            <v-card-text>
            <v-select
                :label="this.$t('invoicing.editModelLabel')"
                v-model="selectedModel"
                :items="models"
                :disabled="!isModelChangeable"
                :hint="this.$t('invoicing.editModelHint')"
                persistent-hint
                class="my-3"
            ></v-select>
            <v-container v-if="readOnly">
                <v-row>
                    {{$t("invoicing.transportEventCannotBeModified")}}
                    <br>{{$t("common.reason", {reason: readOnlyReason})}}
                </v-row>
              <v-container v-if="transportEvent.removed" class="pa-0 mt-4">
                <v-row>
                  <v-btn
                      @click="transportEvent.removed = false"
                      color="primary">
                      {{$t("invoicing.undoRemove")}}
                  </v-btn>
                </v-row>
                <v-row>
                  <span>Tapahtuma pysyy poistettuna, jos et tallenna sitä muokkaamisen jälkeen</span>
                </v-row>
              </v-container>
            </v-container>
            <v-slide-y-transition group hide-on-leave>
                <LikumaForm
                    v-if="selectedModel === transportEventType.Likuma"
                    ref="form"
                    key=1
                    :isNew="!value"
                    :readOnly="readOnly"
                    :originalTransportEvent="transportEvent"
                    @correctionRequested="setCorrectionMode"
                    @close-dialog="$emit('close')"
                ></LikumaForm>
                <KelaForm
                  v-else-if="selectedModel === transportEventType.Kela"
                  ref="form"
                  key=2
                  :isNew="!value"
                  :readOnly="readOnly"
                  :originalTransportEvent="transportEvent"
                  @validationFailed="showValidationErrors"
                  @requestSSN="getSSN"
                  @saveIncomplete="modifyTransportEvent"
                ></KelaForm>
                <ReceiptForm
                 v-else-if="selectedModel === transportEventType.Receipt"
                 ref="form"
                 key=3
                  :isNew="!value"
                  :readOnly="readOnly"
                  :originalTransportEvent="transportEvent"
                  @validationFailed="showValidationErrors"
                  @requestSSN="getSSN"
                  @close-dialog="$emit('close')"
                ></ReceiptForm>
                <KeusoteForm
                    v-if="selectedModel === transportEventType.Keusote"
                    ref="form"
                    key=4
                    :isNew="!value"
                    :readOnly="readOnly"
                    :originalTransportEvent="transportEvent"
                ></KeusoteForm>
                <TaxiPlusForm
                    v-if="selectedModel === transportEventType.TaxiPlus"
                    ref="form"
                    key=5
                    :isNew="!value"
                    :readOnly="readOnly"
                    :originalTransportEvent="transportEvent"
                ></TaxiPlusForm>
                <TransportEventForm
                    v-else-if="selectedModel === transportEventType.Normal"
                    ref="form"
                    key=6
                    :originalTransportEvent="transportEvent"
                ></TransportEventForm>
            </v-slide-y-transition>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="showComboDialogButton"
                  @click="openCombineDialog"
                  color="success"
                >
                  Yhdistä tilaukseen
                </v-btn>
                <v-btn
                    v-if="correctionMode"
                    @click="correctTransportEvent"
                    color="success"
                    :loading="sending"
                >
                  {{$t("invoicing.sendCorrectedEvent")}}
                </v-btn>
                <v-btn
                    v-else-if="!value"
                    @click="createTransportEvent"
                    color="success"
                    :loading="sending"
                    :disabled="readOnly"
                >
                    {{$t("invoicing.addEvent")}}
                </v-btn>
                <v-btn
                    v-else
                    @click="modifyTransportEvent"
                    :loading="sending"
                    :disabled="readOnly"
                    color="primary"
                >
                    {{$t("common.saveChanges")}}
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.close")}}
                </v-btn>
                <v-btn
                  v-if="showSetNotToBeInvoiced"
                  @click="setNotToBeInvoiced"
                  :loading="sendingNotToBeInvoiced"
                  color="error"
                  class="ml-4"
                >
                  {{$t("invoicing.setNotToBeInvoiced")}}
                </v-btn>
                <v-btn
                  v-if="!!value"
                  @click="deleteTransportEvent"
                  :loading="sendingDelete"
                  :disabled="readOnly"
                  color="error"
                  class="ml-4"
                >
                  {{$t("invoicing.deleteEvent")}}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-col>
        <v-slide-x-transition hide-on-leave>
          <v-col
            v-if="browseReceipts"
            cols=5
            class="pa-0 ml-1">
          <v-card>
              <v-toolbar short dark color="primary">
                  <v-card-title>
                    Selvitykset
                  </v-card-title>
              </v-toolbar>
              <v-card-text>
                <v-list>
                <v-subheader>Valitse selvitys</v-subheader>
                <v-list-item-group
                        v-model="selectedReceiptIndex"
                        color="primary"
                      >
                  <v-list-item v-for="(receiptForm, i) in receiptForms" :key="i">
                    <v-list-item-content>
                      <v-list-item-title>{{receiptForm.companyName}} {{receiptForm.region}} {{receiptForm.tripDate}}</v-list-item-title>
                      <v-list-item-subtitle>Klo. {{receiptForm.started}} - {{receiptForm.ended}}, auto {{receiptForm.carNumber}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                </v-list>
                <v-slide-y-transition hide-on-leave>
                  <div v-if="selectedReceipt">
                    <v-subheader>Selvityksen tiedot</v-subheader>
                    <v-row dense>
                      <v-col cols=6>
                        <v-text-field
                            v-model="selectedReceipt.companyName"
                            label="Yrityksen nimi"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field
                            v-model="selectedReceipt.companyId"
                            label="Y-tunnus"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field
                            v-model="selectedReceipt.email"
                            label="Sähköposti"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field
                            v-model="selectedReceipt.phoneNumber"
                            label="Puhelinnumero"
                            readonly
                        ></v-text-field>
                    </v-col>
                    </v-row>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            v-model="selectedReceipt.tripDate"
                            label="Matkapäivä"
                            readonly
                        ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="selectedReceipt.started"
                              label="Aloitusaika"
                              readonly
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="selectedReceipt.ended"
                              label="Lopetusaika"
                              readonly
                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols=6>
                        <v-text-field
                              v-model="selectedReceipt.region"
                              label="Maakunta"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols=6>
                        <v-text-field
                              v-model="selectedReceipt.carNumber"
                              label="Auton numero"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols=12>
                        <v-text-field
                              v-model="selectedReceipt.orderId"
                              label="Tilaustunnus"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols=6>
                        <v-text-field
                              v-model="selectedReceipt.startingPoint"
                              label="Lähtöosoite"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols=6>
                        <v-text-field
                              v-model="selectedReceipt.destination"
                              label="Kohdeosoite"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols=6>
                        <v-text-field
                              v-model="selectedReceipt.deductible"
                              label="Omavastuu"
                              uffix="€"
                              readonly
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <p v-if="selectedReceipt.noReceipt">Ei kuvaa kuitista!</p>
                    </v-row>
                      <!-- <v-img
                      v-else
                      src="@/assets/20210330115502-0-0.jpeg"
                      max-height=300
                      @click="zoomPicture = '@/assets/20210330115502-0-0.jpeg'; showZoom = true;"></v-img>
                    </v-row>
                    <v-dialog v-model="showZoom" max-width="65vw" >
                      <v-img
                      src="@/assets/20210330115502-0-0.jpeg"
                      @click="showZoom = false"></v-img>
                    </v-dialog> -->
                  </div>
                </v-slide-y-transition>
              </v-card-text>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn
                    @click="mergeReceiptToEvent"
                    color="primary"
                >
                    Vie tiedot tapahtumalle
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
          </v-col>
        </v-slide-x-transition>
      </v-row>
      <CombineEventsDialog
        :isOpen="combineEventsDialog"
        :transportEventId="transportEvent.id"
        :transportEventOrderId="transportEvent.orderId"
        :openTransportEvent="transportEvent"
        @combined="(newTpEvent) => $emit('changed', newTpEvent)"
        @failed="showCombiningFailed"
        @close="combineEventsDialog = false"
      >
      </CombineEventsDialog>
        <v-snackbar
            v-model="errorSnackbar"
            vertical
            color="error"
            style="white-space: pre;"
        >
          {{errorText}}
            <v-btn
                text
                @click="errorSnackbar = false"
            >
                {{$t("common.close")}}
            </v-btn>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { TransportEventStatusMixin } from "../../models/TransportEventStatusTypes";
import { TransportEventTypeMixin } from "../../models/TransportEventTypes";
import { TransportEventInvoicingMixin } from "../../models/TransportEventInvoicingStates";
import TransportEventForm from "./TransportEventForm";
import LikumaForm from "./LikumaForm";
import KeusoteForm from "./KeusoteForm";
import TaxiPlusForm from "./TaxiPlusForm";
import KelaForm from "./KelaForm";
import ReceiptForm from "./ReceiptForm";
import CombineEventsDialog from "./CombineEventsDialog";
import moment from "moment";
import _ from "lodash";

export default {
    components: {
        LikumaForm,
        KeusoteForm,
        TaxiPlusForm,
        KelaForm,
        ReceiptForm,
        TransportEventForm,
        CombineEventsDialog
    },
    mixins: [ TransportEventStatusMixin, TransportEventTypeMixin, TransportEventInvoicingMixin ],
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            browseReceipts: false,
            zoomPicture: null,
            showZoom: false,
            receiptForms: [],
            /* {
          companyId: "3099917-7",
          companyName: "Router Group Oy",
          email: "support@routergroup.fi",
          phoneNumber: "-",
          receipt: "assets/receiptImage.png",
          tripDate: "2021-04-30",
          started: "12:03",
          ended: "12:24",
          region: "Uusimaa",
          carNumber: "313",
          orderId: "-",
          startingPoint: "Testikatu 1 A 2",
          destination: "Testitie 13",
          deductible: "5"
        },
        {
          companyId: "3099917-7",
          companyName: "Router Group Oy",
          email: "support@routergroup.fi",
          phoneNumber: "-",
          noReceipt: true,
          tripDate: "2021-04-29",
          started: "16:37",
          ended: "16:56",
          region: "Uusimaa",
          carNumber: "313",
          orderId: "-",
          startingPoint: "Testikatu 1 A 2",
          destination: "Testitie 13",
          deductible: "3"
        }
      ], */
            selectedReceiptIndex: null,
            transportEvent: {
                customer: {},
                fare: { vatPercent: 10, extras: [] },
                trip: {}
            },
            selectedModel: 3,
            isModelChangeable: false,
            valid: true,
            sending: false,
            sendingDelete: false,
            sendingNotToBeInvoiced: false,
            errorSnackbar: false,
            errorText: "",
            correctionMode: false,
            combineEventsDialog: false
        };
    },
    computed: {
        models() {
            if (this.$store.state.user.tenant === "ltx") {
                return [
                    { value: this.transportEventType.Likuma, text: "Likuma" },
                    { value: this.transportEventType.Receipt, text: this.$t("invoicing.transportEventTypes.receipt.title") },
                    { value: this.transportEventType.Keusote, text: "Keusote" },
                    { value: this.transportEventType.TaxiPlus, text: "TaxiPlus" }];
            }
            if (this.$store.state.user.tenant === "taksikuutio") {
                return [{ value: this.transportEventType.Kela, text: "Kela" }];
            }
            return [{ value: this.transportEventType.Normal, text: "Transport event" },
                { value: this.transportEventType.Likuma, text: "Likuma" },
                { value: this.transportEventType.Kela, text: "Kela" },
                { value: this.transportEventType.Receipt, text: this.$t("invoicing.transportEventTypes.receipt.title") }
            ];
        },
        readOnly() {
            return !this.correctionMode && this.value && (!this.transportEvent ||
                this.transportEvent.removed ||
                (this.transportEvent.eventType === this.transportEventType.Kela && (this.transportEvent.validationStatus === 7 || this.transportEvent.validationStatus === 8)) ||
                ((this.transportEvent.eventType === this.transportEventType.Keusote || this.transportEvent.eventType === this.transportEventType.TaxiPlus) && this.transportEvent.validationStatus === 7) ||
                (this.transportEvent.salesInvoiceStatus !== 1 && (this.transportEvent.eventType !== this.transportEventType.Receipt && this.transportEvent.eventType !== this.transportEventType.Keusote &&
                 this.transportEvent.eventType !== this.transportEventType.TaxiPlus)) ||
                (this.transportEvent.validationStatus === this.validationStatusTypes.ReadyForValidation ||
                    this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForInternalValidation ||
                    this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForExternalValidation
                ));
        },
        readOnlyReason() {
            if (!this.transportEvent) return "Tapahtumaa ei ole olemassa";
            if (this.transportEvent.removed) return "Tapahtuma on poistettu";
            if (this.transportEvent.salesInvoiceStatus !== 1) {
                if (this.transportEvent.salesInvoiceStatus === 2) {
                    return "Tapahtuma on menossa laskutukseen";
                }
                return "Tapahtuma on lähetetty laskutukseen";
            }
            if ((this.transportEvent.validationStatus === this.validationStatusTypes.ReadyForValidation ||
                this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForInternalValidation ||
                this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForExternalValidation)) {
                return "Tapahtuma on tarkistettavana";
            }
            if (this.transportEvent.eventType === this.transportEventType.Kela && (this.transportEvent.validationStatus === 7 || this.transportEvent.validationStatus === 8)) {
                return "Kela on hyväksynyt tapahtuman";
            }
            return "Tuntematon";
        },
        selectedReceipt() {
            if (this.selectedReceiptIndex < 0) return null;
            return this.receiptForms[this.selectedReceiptIndex];
        },
        showSetNotToBeInvoiced() {
            if (this.$store.state.user.tenant === "ltx" &&
        (this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Unprocessed || this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Waiting) &&
        (this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.Unprocessed || this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.NotToBeInvoiced)) {
                return true;
            }
            return false;
        },
        showComboDialogButton() {
            if ((this.transportEvent.eventType === this.transportEventType.Kela && !this.transportEvent.trip.routeNumber) ||
        ((this.transportEvent.validationStatus === this.validationStatusTypes.Unknown || this.transportEvent.validationStatus === this.validationStatusTypes.New ||
          this.transportEvent.validationStatus === this.validationStatusTypes.internalValidationFailed || this.transportEvent.validationStatus === this.validationStatusTypes.ExternalValidationFailed) &&
          (this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Unprocessed || this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Waiting ||
           this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.NotToBeInvoiced) &&
           (this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.Unprocessed || this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.NotToBeInvoiced ||
            this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.NotToBeInvoiced))) {
                return true;
            }
            return false;
        }
    },
    watch: {
        isOpen(val, oldVal) {
            if (this.$refs && this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.correctionMode = false;
            this.combineEventsDialog = false;
        },
        models: {
            immediate: true,
            handler(val) {
                if (val.filter(m => m.value === this.selectedModel).length < 1) {
                    this.selectedModel = val[0].value;
                }
            }
        },
        value: {
            immediate: true,
            handler(val) {
                let transportEvent = (!this.value) ? {} : _.cloneDeep(this.value);
                if (!transportEvent.customer) transportEvent.customer = {};
                if (!transportEvent.user) transportEvent.user = {};
                if (!transportEvent.fare) transportEvent.fare = { vatPercent: 10 };
                if (!transportEvent.fare.extras) transportEvent.fare.extras = [];
                if (!transportEvent.fare.tariffs) transportEvent.fare.tariffs = [];
                if (!transportEvent.fare.waitFees) transportEvent.fare.waitFees = [];
                if (!transportEvent.trip) transportEvent.trip = {};
                if (transportEvent.trip.started) transportEvent.trip.started = moment(transportEvent.trip.started).format("YYYY-MM-DDTHH:mm:ss");
                if (transportEvent.trip.ended) transportEvent.trip.ended = moment(transportEvent.trip.ended).format("YYYY-MM-DDTHH:mm:ss");
                if (!transportEvent.kelaData) transportEvent.kelaData = { passengerEligibility: true };
                if (!transportEvent.eventType) transportEvent.eventType = this.models[0].value;

                this.transportEvent = transportEvent;
                this.selectedModel = transportEvent.eventType;
                this.isModelChangeable = !this.value || transportEvent.eventType === this.transportEventType.Receipt ||
          transportEvent.eventType === this.transportEventType.TaxiPlus || transportEvent.eventType === this.transportEventType.Keusote;
                this.correctionMode = false;
            }
        }
    },
    methods: {
        createTransportEvent() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.sending = true;
            this.transportEvent = this.$refs.form.transportEvent;
            if (!this.transportEvent.validationStatus ||
                this.transportEvent.validationStatus === this.validationStatusTypes.Unknown) {
                this.transportEvent.validationStatus = this.validationStatusTypes.New;
            }
            this.setEventType();
            this.$store.dispatch("transportEventTable/addTransportEvent", this.transportEvent)
                .then(() => {
                    this.$emit("save", this.transportEvent);
                })
                .catch(e => {
                    this.errorSnackbar = true;
                    this.errorText = "Creating transport event failed.\n" + e + "\n";
                    if (e.response.data) {
                        this.errorText += this.getErrorMessageFromServer(e.response.data);
                    } else {
                        this.errorText += e.message;
                    }
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        modifyTransportEvent() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.sending = true;
            this.setEventType();
            this.$store.dispatch("transportEventTable/updateTransportEvent", this.$refs.form.transportEvent)
                .then((response) => {
                    this.transportEvent = this.$refs.form.transportEvent;
                    this.$emit("save", this.transportEvent);
                })
                .catch(e => {
                    this.errorSnackbar = true;
                    this.errorText = "Saving transport event failed.\n";
                    if (e.response.data) {
                        this.errorText += this.getErrorMessageFromServer(e.response.data);
                    } else {
                        this.errorText += e.message;
                    }
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteTransportEvent() {
            if (!window.confirm("Transport event will be permanently deleted. Are you sure you want to continue?")) {
                return;
            }
            this.sendingDelete = true;
            this.$store.dispatch("transportEventTable/deleteTransportEvent", this.transportEvent.id)
                .then((response) => {
                    this.$emit("delete");
                })
                .catch(e => {
                    this.errorSnackbar = true;
                    this.errorText = "Deleting transport event failed.\n";
                    if (e.response.data) {
                        this.errorText += this.getErrorMessageFromServer(e.response.data);
                    } else {
                        this.errorText += e.message;
                    }
                })
                .finally(() => {
                    this.sendingDelete = false;
                });
        },
        setNotToBeInvoiced() {
            this.sendingNotToBeInvoiced = true;
            this.$store.dispatch("transportEventTable/updateTransportEventStatuses", {
                id: this.transportEvent.id,
                statuses: {
                    salesInvoiceState: this.salesInvoiceStates.NotToBeInvoiced,
                    purchaseInvoiceState: this.purchaseInvoiceStates.NotToBeInvoiced
                }
            })
                .finally(() => {
                    this.sendingNotToBeInvoiced = false;
                });
        },
        setEventType() {
            this.$refs.form.transportEvent.eventType = this.selectedModel;
        },
        setCorrectionMode() {
            this.correctionMode = true;
        },
        openCombineDialog() {
            this.combineEventsDialog = true;
        },
        correctTransportEvent() {
            if (!this.$refs.form.validate()) {
                return;
            }
            if (!window.confirm("Transport event can be corrected only once. Are you sure that this is the correct and final version of the event?")) {
                return;
            }
            this.sending = true;
            this.transportEvent = this.$refs.form.transportEvent;
            this.transportEvent.validationStatus = this.validationStatusTypes.ReadyForCorrection;
            this.$store.dispatch("transportEventTable/correctTransportEvent", this.transportEvent)
                .then(() => {
                    this.$emit("save", this.transportEvent);
                })
                .catch(e => {
                    this.errorSnackbar = true;
                    this.errorText = "Creating transport event failed.\n";
                    if (e.response.data) {
                        this.errorText += this.getErrorMessageFromServer(e.response.data);
                    } else {
                        this.errorText += e.message;
                    }
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        openCombineWindow() {
            prompt("Syötä pitkä tilaustunnus");
        },
        getSSN() {

        },
        mergeReceiptToEvent() {
            this.transportEvent.trip.carNumber = this.selectedReceipt.carNumber;
            this.transportEvent.trip.startingPoint = this.selectedReceipt.startingPoint;
            this.transportEvent.trip.destination = this.selectedReceipt.destination;
            this.transportEvent.fare.deductible = this.selectedReceipt.deductible;
        },
        showValidationErrors(errors) {
            this.errorText = "Tapahtuman tallentaminen epäonnistui:";
            errors.forEach(e => {
                this.errorText += "\n" + e;
            });
            this.errorSnackbar = true;
        },
        showCombiningFailed(error) {
            this.errorText = "Yhdistäminen epäonnistui: " + error;
            this.errorSnackbar = true;
        },
        getErrorMessageFromServer(message) {
            if (message.toString().startsWith("<") &&
            message.toString().indexOf("One or more validation errors") > -1) {
                return "Tarkista tiedot ja yritä uudelleen.";
            }

            message = JSON.stringify(message);
            let titleInx = message.indexOf("\"Title\":");
            let statusInx = message.indexOf("\"Status\":");
            let errorCodeAndMessage = message.substring(titleInx + 9, statusInx - 2);
            let errorMessageFromServer = errorCodeAndMessage.substring(errorCodeAndMessage.indexOf(": ") + 1);

            return errorMessageFromServer || errorCodeAndMessage;
        }
    }
};
</script>

<style>

</style>
