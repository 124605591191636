import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedTransportEvents: [],
    searchParams: null,
    sortByParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null,
    extensions: null,
    options: {
        sortBy: ["trip.started"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50
    }
};

// getters
const getters = {
};

// actions
const actions = {
    async searchTransportEvents({ commit }, { searchParams, sortByParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
              moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
              JSON.stringify(state.searchParams) === JSON.stringify(searchParams) &&
              JSON.stringify(state.sortByParams) === JSON.stringify(sortByParams)) {
            return;
        }
        let timestamp = moment();
        commit("SET_FETCHED_TIMESTAMP", timestamp);
        await RepositoryFactory.get("invoicing").find(searchParams, sortByParams, top)
            .then(response => {
                if (timestamp !== state.fetchedTimestamp) { return; }
                commit("SET_TRANSPORT_EVENTS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_SORT_BY_PARAMS", JSON.parse(JSON.stringify(sortByParams)));
                commit("SET_EXTENSIONS", response.data.extensions);
            });
    },
    async loadMoreTransportEvents({ commit }, { top = 100 }) {
        await RepositoryFactory.get("invoicing").find(
            state.searchParams,
            state.sortByParams,
            top,
            Math.floor(state.searchedTransportEvents.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_TRANSPORT_EVENTS", response.data.results);
            });
    },
    async downloadTransportEvents() {
        await RepositoryFactory.get("invoicing").download(state.searchParams);
    },
    async addTransportEvent({ commit }, transportEvent) {
        await RepositoryFactory.get("invoicing").create(transportEvent)
            .then(() => {
                commit("ADD_TRANSPORT_EVENT", transportEvent);
            });
    },
    async updateTransportEvent({ commit }, transportEvent) {
        await RepositoryFactory.get("invoicing").update(transportEvent)
            .then(() => {
                commit("UPDATE_TRANSPORT_EVENT", transportEvent);
            });
    },
    async deleteTransportEvent({ commit }, id) {
        await RepositoryFactory.get("invoicing").delete(id)
            .then(() => {
                commit("REMOVE_TRANSPORT_EVENT", id);
            });
    },
    async correctTransportEvent({ commit }, transportEvent) {
        await RepositoryFactory.get("invoicing").correct(transportEvent)
            .then(() => {
                commit("UPDATE_TRANSPORT_EVENT", transportEvent);
            });
    },
    async cancelLikumaTrip({ commit }, transportEventData) {
        await RepositoryFactory.get("invoicing")
            .cancelLikumaTrip(transportEventData.transportEvent.id,
                transportEventData.transportEvent.fare.cardNumber,
                transportEventData.transportEvent.likumaCertificateId,
                transportEventData.transportEvent.trip.tripType)
            .then(() => {
                commit("REMOVE_TRANSPORT_EVENT", transportEventData.transportEvent.id);
            });
    },
    async updateOptions({ commit }, options) {
        commit("SET_OPTIONS", options);
    },
    async updateTransportEventComments({ commit }, { id, comments }) {
        await RepositoryFactory.get("invoicing").updateComments(id, comments)
            .then(() => {
                commit("SET_TRANSPORTEVENT_COMMENTS", { id, comments });
            });
    },
    async updateTransportEventStatuses({ commit }, { id, statuses }) {
        await RepositoryFactory.get("invoicing").updateStatuses(id, statuses)
            .then(() => {
                commit("SET_TRANSPORTEVENT_STATUSES", { id, statuses });
            });
    },
    async getDuplicateVerifoneTransportEvents({ commit }, id) {
        await RepositoryFactory.get("invoicing").getDuplicateVerifoneTransportEvents(id)
            .then(response => {
                commit("SET_TRANSPORT_EVENTS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
            });
    }
};

// mutations
const mutations = {
    ADD_TRANSPORT_EVENT(state, transportEvent) {
        state.searchedTransportEvents.push(transportEvent);
    },
    SET_TRANSPORT_EVENTS(state, transportEvents) {
        state.searchedTransportEvents = transportEvents;
    },
    ADD_SEARCHED_TRANSPORT_EVENTS(state, transportEvents) {
        state.searchedTransportEvents = state.searchedTransportEvents.concat(transportEvents);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_SORT_BY_PARAMS(state, sortByParams) {
        state.sortByParams = sortByParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    },
    UPDATE_TRANSPORT_EVENT(state, transportEvent) {
        state.searchedTransportEvents = [
            ...state.searchedTransportEvents.map(
                item => item.id !== transportEvent.id ? item : { ...item, ...transportEvent })
        ];
    },
    REMOVE_TRANSPORT_EVENT(state, id) {
        let index = state.searchedTransportEvents.findIndex(te => te.id === id);
        state.searchedTransportEvents.splice(index, 1);
    },
    SET_TRANSPORTEVENT_COMMENTS(state, { id, comments }) {
        let index = state.searchedTransportEvents.findIndex(te => te.id === id);
        state.searchedTransportEvents[index].comments = comments;
    },
    SET_TRANSPORTEVENT_STATUSES(state, { id, statuses }) {
        let index = state.searchedTransportEvents.findIndex(te => te.id === id);
        state.searchedTransportEvents[index].salesInvoiceStatus = statuses.salesInvoiceState;
        state.searchedTransportEvents[index].purchaseInvoiceStatus = statuses.purchaseInvoiceState;
    },
    SET_EXTENSIONS(state, extensions) {
        state.extensions = extensions;
    },
    SET_OPTIONS(state, options) {
        Object.assign(state.options, options);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
