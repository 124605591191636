import { invoicingService } from "./repository";
import axios from "axios";
import qs from "qs";
import AuthService from "../../msal";

const authService = new AuthService();
const resource = "/v1";

export default {
    data() {
        return {
            cancel: Object
        };
    },
    getCancelRequest() {
        return this.cancel;
    },
    async find(searchParams, orderByParams, top, page = 1 /* tenantId */) {
        this.cancel = axios.CancelToken.source();
        return invoicingService.get(`${resource}/transportevents`, {
            params: {
                ...searchParams,
                ...orderByParams,
                top,
                page
            },
            cancelToken: this.cancel.token,
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async get(id) {
        return invoicingService.get(`${resource}/transportevents/${id}`);
    },
    async create(transportEvent) {
        const { userId } = await authService.getUser();

        transportEvent.modifier = userId;

        return invoicingService.post(`${resource}/transportevents`, transportEvent);
    },
    async update(transportEvent) {
        const { userId } = await authService.getUser();

        transportEvent.modifier = userId;

        return invoicingService.put(`${resource}/transportevents/${transportEvent.id}`, transportEvent);
    },
    async delete(id) {
        return invoicingService.delete(`${resource}/transportevents/${id}`);
    },
    async combine(target, source) {
        return invoicingService.post(`${resource}/combine/${target}/source/${source}`);
    },
    async getCombinedEvent(tripEventId, receiptEventId) {
        return invoicingService.get(`${resource}/combine/${tripEventId}/combined/${receiptEventId}`);
    },
    async updateInvoicingOptions(id, invoicingOptions) {
        return invoicingService.put(`${resource}/invoices/options/${id}`, invoicingOptions);
    },
    async updateStatuses(id, statuses) {
        return invoicingService.put(`${resource}/transportevents/${id}/statuses`, statuses);
    },
    async updateComments(id, comments) {
        return invoicingService.put(`${resource}/transportevents/${id}/comments`, { comments });
    },
    async getSSN(id) {
        return invoicingService.get(`${resource}/transportevents/${id}/ssn`);
    },
    async cancelLikumaTrip(transportEventId, cardNumber, certificateId, tripType) {
        return invoicingService.post(`${resource}/likuma/cancel`, { transportEventId, cardNumber, certificateId, tripType });
    },
    async createCostCenterSalesInvoices(costCenterIds, from, to) {
        return invoicingService.post(`${resource}/costcenter/salesInvoices/create`, { from, to, costCenterIds });
    },
    async createLikumaPurchaseInvoices() {
        return invoicingService.post(`${resource}/likuma/purchaseInvoices/create`);
    },
    async createEmvPurchaseInvoices() {
        return invoicingService.post(`${resource}/emv/purchaseInvoices/create`);
    },
    async createTaksiniPurchaseInvoices() {
        return invoicingService.post(`${resource}/taksini/purchaseInvoices/create`);
    },
    async createEventsFromMissingVerifones(events) {
        return invoicingService.post(`${resource}/verifone/createmissing`, events);
    },
    async correct(transportEvent) {
        const { userId } = await authService.getUser();

        transportEvent.modifier = userId;

        return invoicingService.post(`${resource}/transportevents/${transportEvent.id}`, transportEvent);
    },
    async download(params) {
        return invoicingService.get(`${resource}/transportevents/download`, {
            params: {
                ...params
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        })
            .then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", "TransportEvents.csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            });
    },
    async getFailReasons() {
        return invoicingService.get("/statistics/transporteventfailreasons");
    },
    async getSalesInvoices() {
        return invoicingService.get("/statistics/transporteventsalesinvoices");
    },
    async getPurchaseInvoices() {
        return invoicingService.get("/statistics/transporteventpurchaseinvoices");
    },
    async getTripStatusesByDay(start, end) {
        return invoicingService.get("/statistics/tripstatusesbyday", {
            params: {
                start, end
            }
        });
    },
    async getTripStatusesByTimeRange(start, end) {
        return invoicingService.get("/statistics/tripstatusesbytimerange", {
            params: {
                start, end
            }
        });
    },
    async getCostCenterReadyForSalesInvoicing(from, to) {
        return invoicingService.get("/statistics/invoicing/readyforsalesinvoicing/costcenter", {
            params: {
                from, to
            }
        });
    },
    async getLikumaReadyForPurchaseInvoicing() {
        return invoicingService.get("/statistics/invoicing/readyforpurchaseinvoicing/likuma");
    },
    async getEmvReadyForPurchaseInvoicing() {
        return invoicingService.get("/statistics/invoicing/readyforpurchaseinvoicing/emv");
    },
    async getTaksiniReadyForPurchaseInvoicing() {
        return invoicingService.get("/statistics/invoicing/readyforpurchaseinvoicing/taksini");
    },
    async getCreatedPurchaseInvoices() {
        return invoicingService.get("/statistics/invoicing/createdpurchaseinvoices");
    },
    async updateCreatedPurchaseInvoices() {
        return invoicingService.post("/statistics/invoicing/createdpurchaseinvoices/update");
    },
    async getAllMissingVerifoneEvents() {
        return invoicingService.get(`${resource}/verifone/missing`);
    },
    async getAllPaytrailTransactions() {
        return invoicingService.get(`${resource}/paytrail/reports`);
    },
    async handlePaytrailTransaction(operatorId, settlementId, checkoutReference) {
        return invoicingService.patch(`${resource}/paytrail/transaction/handle`,
            { operatorId, settlementId, checkoutReference });
    },
    async setPaytrailEventReadyForPurchasing(operatorId, settlementId, archiveNumber, checkoutReference) {
        return invoicingService.patch(`${resource}/paytrail/transaction/settle`,
            { operatorId, settlementId, archiveNumber, checkoutReference });
    },
    async createEventsFromPaytrailTransactions(events) {
        return invoicingService.post(`${resource}/paytrail/transactions/create`, events);
    },
    // TODO: endpoint for generic card validity check
    async getCardValidity(cardNumber, serviceNumber) {
        return invoicingService.get(`${resource}/taxicard/${cardNumber}/validity/semelcf`, { params: { serviceNumber } });
    },
    async rehandleTaxicardPaymentToSemelCF(id, externalId, authorize) {
        return invoicingService.post(`${resource}/taxicard/rehandlepayment/semelcf`, { id, externalId, authorize });
    },
    async getVerifoneTransportEventDetails(id) {
        return invoicingService.get(`${resource}/verifone/transportevent/${id}`);
    },
    async getDuplicateVerifoneTransportEvents(id) {
        return invoicingService.get(`${resource}/verifone/transportevent/${id}/duplicates`);
    },
    async setVerifoneTransportEventToPurchaseInvoicing(id) {
        return invoicingService.post(`${resource}/verifone/transportevent/${id}/handle`);
    },
    async getSalesInvoicingBatchRows() {
        return invoicingService.get(`${resource}/invoices/batches/sales`);
    },
    async getPurchaseInvoicingBatchRows() {
        return invoicingService.get(`${resource}/invoices/batches/purchase`);
    },
    async getAllInvoicingOptions() {
        return invoicingService.get(`${resource}/invoices/options`);
    },
    async getAllInvoicingFilters() {
        return invoicingService.get(`${resource}/filters/invoicing`);
    },
    async getSalesInvoicingFilters() {
        return invoicingService.get(`${resource}/filters/invoicing/sales`);
    },
    async getPurchaseInvoicingFilters() {
        return invoicingService.get(`${resource}/filters/invoicing/purchase`);
    },
    async getAllTransportEventFilters() {
        return invoicingService.get(`${resource}/filters/transportevents`);
    },
    async startSalesInvoicing(eventFilterId, invoicingOptionsId) {
        return invoicingService.post(`${resource}/filters/invoicing/sales/create`,
            { eventFilterId, invoicingOptionsId });
    },
    async startPurchaseInvoicing(eventFilterId, invoicingOptionsId) {
        return invoicingService.post(`${resource}/filters/invoicing/purchase/create`,
            { eventFilterId, invoicingOptionsId });
    }
};
