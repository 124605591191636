<template>
  <v-form ref="form" v-if="transportEvent" v-model="valid">
    <v-tabs v-model="tab">
      <v-tab v-for="(item, i) in tabs" :key="i">
        <div v-if="(i !== 1) || (i === 1 && cancellableEvent)">
          {{ item }}
        </div>
      </v-tab>
    <v-tab-item key="0">
    <v-row v-if="this.transportEvent.salesInvoiceStatus !== 1">
      <v-row v-if="transportEvent.isCompensated">
        {{ $t("invoicing.eventHasBeenCorrected") }}
      </v-row>
      <v-row v-else>
        <v-col cols="auto">
          <v-btn @click="setToBeCorrected" color="primary">
            {{ $t("invoicing.makeCorrections") }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          {{ $t("invoicing.makeCorrectionsInfo") }}
        </v-col>
      </v-row>
    </v-row>
    <v-subheader class="mt-3">{{
      $t("invoicing.transportEventTypes.likuma.likumaTripInfo")
    }}</v-subheader>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.orderId"
          label="Booking id"
          :disabled="readOnly"
          :rules="likumaOrderIdRules"
          :counter="likumaOrderIdLength"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.likumaTripId"
          label="Trip id"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(10)]"
          counter="10"
          required
        ></v-text-field>
        <v-text-field
          v-model="likumaCardNumber"
          label="Card number"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(20)]"
          counter="20"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.likumaCertificateId"
          label="Certificate id"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(6)]"
          counter="6"
          required
        ></v-text-field>
        <v-select
          v-model="transportEvent.trip.welfareTripType"
          label="Decision id"
          :disabled="readOnly"
          :items="decisionIds"
          :rules="[requiredRule]"
          required
        ></v-select>
        <v-select
          v-model="transportEvent.trip.tripType"
          label="Trip type"
          :disabled="readOnly"
          :items="tripTypes"
          :rules="[requiredRule]"
          required
        ></v-select>
        <v-text-field
          v-model="transportEvent.trip.started"
          label="Trip start timestamp"
          :disabled="readOnly"
          :rules="[requiredRule, dateTimeRule]"
          :type="tripStartedTextFieldType"
          @dblclick="dblClickTripStartedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.ended"
          label="Trip end timestamp"
          :disabled="readOnly"
          :rules="[requiredRule, dateTimeRule]"
          :type="tripEndedTextFieldType"
          @dblclick="dblClickTripEndedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.duration"
          label="Trip time"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(3)]"
          counter="3"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.distance"
          label="Trip distance (m)"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(6)]"
          counter="6"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingTime"
          label="Waiting time"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(4)]"
          counter="4"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.carNumber"
          label="Vehicle id"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(8)]"
          counter="8"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.taximeterSerialNumber"
          label="Meter id"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(14)]"
          counter="14"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentTerminalId"
          label="Service number"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(12)]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.driverId"
          label="Driver id"
          :disabled="readOnly"
          :rules="[maxLengthRule(12)]"
          counter="12"
        ></v-text-field>
        <v-select
          v-model="transportEvent.trip.carPool"
          label="Car pool"
          :disabled="readOnly"
          :items="carPoolValues"
        ></v-select>
        <v-text-field
          v-model="transportEvent.trip.startingPoint"
          label="Start address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.destination"
          label="End address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader class="mt-3">{{
      $t("invoicing.transportEventTypes.likuma.likumaCharge")
    }}</v-subheader>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.receiptNumber"
          label="Receipt number"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(8)]"
          counter="8"
          required
        ></v-text-field>
        <v-select
          v-model="transportEvent.fare.vatPercent"
          :items="[0.0, 10.0, 24.0, 25.5]"
          :rules="[requiredRule]"
          @input="calculateSums"
          label="Vat percent"
          :disabled="readOnly"
          required
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item }} %
          </template>
          <template slot="item" slot-scope="{ item }"> {{ item }} % </template>
        </v-select>
        <v-text-field
          v-model="transportEvent.fare.cost"
          label="Total amount"
          @change="updateFareCostValue"
          :value="transportEvent.fare.cost | formatPrice"
          :disabled="readOnly"
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(8),
          ]"
          counter="9"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.discountAmount"
          label="Discount amount"
          :disabled="readOnly"
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(8)]"
          counter="9"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursement"
          label="Total charge"
          disabled
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(8),
          ]"
          counter="9"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursementVat"
          label="Total charge vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(8)]"
          counter="9"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.deductible"
          label="Total deductible"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(6),
          ]"
          counter="7"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.deductibleVat"
          label="Excess vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingCost"
          label="Total waiting"
          @input="calculateSums"
          :disabled="readOnly"
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingVat"
          label="Waiting vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.highTariff"
          label="High tariff"
          :disabled="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader>{{ $t("invoicing.extraCharges") }} (0-5)</v-subheader>
    <FeeChipGroup
      ref="feeChipGroup"
      :fees="transportEvent.fare.extras"
      :allowedFeeTypes="extraChargeTypes"
      :maxAmount="5"
      :readOnly="readOnly"
      :useLikumaTexts="true"
      @update="updateFees"
    ></FeeChipGroup>
    <v-row>
      <v-textarea
        v-model="transportEvent.comments"
        label="Comments (optional)"
        outlined
        rows="3"
        class="mt-3 mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        v-model="transportEvent.purchaseInvoiceMessage"
        label="Info for purchase invoice (optional)"
        :disabled="readOnly"
        outlined
        rows="3"
        class="mt-3 mx-2"
      ></v-textarea>
    </v-row>
    <v-row v-if="readOnly">
          <v-col>
            <v-btn
              color="primary"
              :loading="commentUpdating"
              @click="updateComment">
              {{$t("invoicing.updateComments")}}
            </v-btn>
          </v-col>
        </v-row>
    <v-switch
      v-model="toBeValidated"
      label="Set to be validated"
      :disabled="readOnly"
      hint="Event cannot be modified during the validation process"
      persistent-hint
      class="mt-0"
    ></v-switch>
    </v-tab-item>
    <v-tab-item key="1">
        <v-row class="mt-6">
          <v-col cols="8" md="4">
            <v-subheader class="mt-3">{{
            $t("invoicing.transportEventTypes.likuma.likumaCancel")
          }}</v-subheader>
          <v-text-field
                v-model="transportEvent.likumaCertificateId"
                label="Likuma Certificate ID"
                readonly
                class="mx-2"
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.likumaTripId"
                label="Likuma Trip ID"
                readonly
                class="mx-2"
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.trip.tripType"
                label="Likuma Trip Type"
                readonly
                class="mx-2"
            ></v-text-field>
          <v-btn depressed color="warning" :disabled="!cancellableEvent" @click="cancelLikumaTrip(transportEvent)">
            Peruuta matka
          </v-btn>
        </v-col>
      </v-row>
      </v-tab-item>
    </v-tabs>
    <v-snackbar
      v-model="errorSnackbar"
      vertical
      color="error"
      style="white-space: pre;">
      {{errorText}}
      <v-btn
        text
        @click="errorSnackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-form>
</template>

<script>
import FeeChipGroup from "./FeeChipGroups/FeeChipGroup";
import { FeeTypeMixin, FeeType } from "../../models/FeeTypes";
import { ValidationStatusType } from "../../models/TransportEventStatusTypes";
import InputRuleMixin from "../Inputs/InputRuleMixin";
import _ from "lodash";

export default {
    components: { FeeChipGroup },
    mixins: [FeeTypeMixin, InputRuleMixin],
    props: {
        originalTransportEvent: Object,
        isNew: Boolean,
        readOnly: Boolean
    },
    data() {
        return {
            transportEvent: _.cloneDeep(this.originalTransportEvent),
            valid: true,
            likumaCardNumber: "",
            decisionIds: ["VPL", "SHL"],
            tripTypes: ["MT01", "MT02", "MT03", "MT04", "MT05", "MT06", "MT07", "MT08", "MT09", "MT10", "MT11", "MT12", "MT13", "MT14", "MT15", "MT16", "MT17", "MT18", "MT19", "MT20", "MT21", "MT22", "MT23", "MT24", "MT25", "MT26", "MT27", "MT28", "MT29", "MT30", "MT31", "MT32", "MT33", "MT34", "MT35", "MT36", "MT37", "MT38", "MT39", "MT40", "MT41", "MT42", "MT43", "MT44", "MT45", "MT46", "MT47", "MT48", "MT49", "MT50", "MT51", "MT52", "MT53", "MT54", "MT55", "MT56", "MT57", "MT58", "MT59", "MT60", "MT61", "MT62", "MT63", "MT64", "MT65", "MT66", "MT67", "MT68", "MT69", "MT70", "MT71", "MT72", "MT73", "MT74", "MT75", "MT76", "MT77", "MT78", "MT79", "MT80", "MT81", "MT82", "MT83", "MT84", "MT85", "MT86", "MT87", "MT88", "MT89", "MT90", "MT91", "MT92", "MT93", "MT94", "MT95", "MT96", "MT97", "MT98", "MT99"],
            carPoolValues: [
                { value: "0", text: "No" },
                { value: "1", text: "Yes" }
                // { value: 2, text: "Yes, deductible not charged" } // Ei käytössä pääkaupunkiseudulla
            ],
            extraChargeTypes: [
                FeeType.AirportFee,
                FeeType.ServiceFee,
                FeeType.AidFee,
                FeeType.HandicapFee,
                FeeType.StretcherFee,
                FeeType.ApproachFee
            ],
            tabs: [
                this.$t("invoicing.transportEventTypes.likuma.title"),
                this.$t("invoicing.transportEventTypes.receipt.cardTransactionInformation")
            ],
            tab: 0,
            errorSnackbar: false,
            errorText: "",
            toBeValidated: false,
            commentUpdating: false,
            tripEndedTextFieldType: "datetime-local",
            tripStartedTextFieldType: "datetime-local"
        };
    },
    computed: {
        cancellableEvent() {
            if (this.transportEvent != null && this.transportEvent.fare != null &&
            this.transportEvent.fare.cardNumber != null &&
                this.transportEvent.fare.cardNumber.length > 0) {
                if (this.transportEvent.validationStatus === 1 &&
            this.transportEvent.salesInvoiceStatus === 1 &&
            this.transportEvent.purchaseInvoiceStatus === 1 &&
            this.transportEvent.likumaCertificateId > 0) {
                    return true;
                }
            }
            return false;
        },
        likumaOrderIdRules() {
            if (this.transportEvent.orderId && this.transportEvent.orderId.startsWith("AAA")) {
                return [
                    this.requiredRule,
                    this.maxLengthRule(21)
                ];
            } else {
                return [
                    this.requiredRule,
                    this.digitRule,
                    this.maxLengthRule(12)
                ];
            }
        },
        likumaOrderIdLength() {
            if (this.transportEvent.orderId && this.transportEvent.orderId.startsWith("AAA")) {
                return 21;
            } else {
                return 12;
            }
        }
    },
    watch: {
        originalTransportEvent: {
            handler: function(val) {
                this.toBeValidated = false;
                this.transportEvent = !val ? {} : _.cloneDeep(val);
                if (!this.transportEvent.fare || !this.transportEvent.fare.extras) {
                    this.transportEvent = {
                        fare: {
                            extras: []
                        }
                    };
                }
                let likumaCardNumber = "";
                if (this.transportEvent != null && this.transportEvent.customer != null &&
            this.transportEvent.customer.cardNumber != null &&
                this.transportEvent.customer.cardNumber.length > 0) {
                    likumaCardNumber = this.transportEvent.customer.cardNumber;
                } else if (this.transportEvent != null && this.transportEvent.fare != null &&
            this.transportEvent.fare.cardNumber != null &&
                this.transportEvent.fare.cardNumber.length > 0) {
                    likumaCardNumber = this.transportEvent.fare.cardNumber;
                }
                this.likumaCardNumber = likumaCardNumber;
            },
            immediate: true
        },
        likumaCardNumber: {
            handler: function(val) {
                this.transportEvent.fare.cardNumber = val;
                this.transportEvent.customer.cardNumber = val;
            },
            immediate: true
        },
        toBeValidated(val) {
            if (val) {
                this.transportEvent.validationStatus =
          ValidationStatusType.ReadyForValidation;
            } else {
                this.transportEvent.validationStatus =
          this.originalTransportEvent.validationStatus;
                this.resetValidation();
            }
        }
    },
    methods: {
        validate() {
            this.transportEvent.user.cardNumber =
        this.transportEvent.customer.cardNumber;
            if (!this.toBeValidated) return true;
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        updateFees(fees) {
            this.transportEvent.fare.extras = fees;
            this.$refs.feeChipGroup.closeFeeDialog();
        },
        updateComment() {
            this.commentUpdating = true;
            this.$store.dispatch("transportEventTable/updateTransportEventComments", {
                id: this.transportEvent.id,
                comments: this.transportEvent.comments
            })
                .finally(() => {
                    this.commentUpdating = false;
                });
        },
        calculateSums() {
            let fare = this.transportEvent.fare;
            fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
            fare.reimbursementVat = this.calculateVat(
                fare.reimbursement,
                fare.vatPercent
            );
            fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
            fare.waitingVat = this.calculateVat(fare.waitingCost, fare.vatPercent);
        },
        calculateVat(sumIncludingVat, vatPercent) {
            let vat = sumIncludingVat - sumIncludingVat / (vatPercent / 100.0 + 1);
            return vat.toFixed(2);
        },
        setToBeCorrected() {
            this.$emit("correctionRequested");
        },
        updateFareCostValue(updateFareCostEvent) {
            this.transportEvent.fare.cost = updateFareCostEvent;
            this.calculateSums();
        },
        dblClickTripEndedTypeChange() {
            if (this.tripEndedTextFieldType === "datetime-local") {
                this.tripEndedTextFieldType = "text";
            } else {
                this.tripEndedTextFieldType = "datetime-local";
            }
        },
        dblClickTripStartedTypeChange() {
            if (this.tripStartedTextFieldType === "datetime-local") {
                this.tripStartedTextFieldType = "text";
            } else {
                this.tripStartedTextFieldType = "datetime-local";
            }
        },
        async cancelLikumaTrip(event) {
            if (!window.confirm("Oletko varma, että haluat peruuttaa tapahtuman? Peruutuksen onnistuessa ajotapahtuma poistetaan. Jos korttinumeroa on muokattu käyttöliittymässä, poistaminen ei kohdistu oikein.")) {
                return;
            }
            this.$store.dispatch("transportEventTable/cancelLikumaTrip", {
                transportEvent: event
            }).then(() => {
                this.$emit("close-dialog");
            })
                .catch(error => {
                    this.errorSnackbar = true;
                    this.errorText = error;
                });
        }
    }
};
</script>

<style>
</style>
