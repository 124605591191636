var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      on: { input: _vm.onDialogInput },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { attrs: { id: "headline" } }, [
              _vm._v(
                _vm._s(
                  _vm.selectedInvoiceOption
                    ? _vm.selectedInvoiceOption.optionsName
                    : ""
                )
              )
            ])
          ]),
          _c("v-card-text", { staticStyle: { color: "black" } }, [
            _vm.selectedInvoiceOption
              ? _c(
                  "div",
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitForm($event)
                          }
                        }
                      },
                      _vm._l(
                        _vm.selectedInvoiceOption.invcoicingOptions,
                        function(option, key) {
                          return _c(
                            "v-row",
                            { key: key, staticClass: "mx-4" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "String"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "Number"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            type: "number",
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "Decimal"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            type: "number",
                                            step: "0.01",
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) === "Date"
                                    ? [
                                        _c("v-date-picker", {
                                          attrs: {
                                            label: option.name,
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "Boolean"
                                    ? [
                                        _c("v-switch", {
                                          attrs: {
                                            label: option.name,
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: _vm.booleanValues[key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.booleanValues,
                                                key,
                                                $$v
                                              )
                                            },
                                            expression: "booleanValues[key]"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) === "Guid"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "DictionaryString"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "DictionaryNumber"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            type: "number",
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(option.type) ===
                                  "DictionaryDecimal"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: option.name,
                                            type: "number",
                                            step: "0.01",
                                            disabled: !option.canBeModified
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }