var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      on: { input: _vm.onDialogInput },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { attrs: { id: "headline" } }, [
              _vm._v(
                _vm._s(
                  _vm.mode === "edit"
                    ? _vm.$t("common.edit")
                    : _vm.$t("common.addNew")
                )
              )
            ])
          ]),
          _c(
            "v-card-text",
            { staticStyle: { color: "black" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.invoiceOptions,
                          "item-text": "optionsName",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.selectedInvoiceOption,
                          callback: function($$v) {
                            _vm.selectedInvoiceOption = $$v
                          },
                          expression: "selectedInvoiceOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.allTransportEventFilters,
                          "item-text": "filterName",
                          "item-value": "rowKey"
                        },
                        model: {
                          value: _vm.selectedEventsFilter,
                          callback: function($$v) {
                            _vm.selectedEventsFilter = $$v
                          },
                          expression: "selectedEventsFilter"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.mode === "edit" ? _vm.emitSave() : _vm.emitCreate()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.mode === "edit"
                          ? _vm.$t("common.save")
                          : _vm.$t("common.create")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }